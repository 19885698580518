import { configureStore } from "@reduxjs/toolkit";
import authUserReducer from './authSlice';
import customerReducer from './customerSlice';

const store = configureStore({
  reducer: {
    authUser: authUserReducer,
    customer: customerReducer,
  }
})

export default store;