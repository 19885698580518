/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/alt-text */


import * as CheckboxPrimitive from "@radix-ui/react-checkbox"
import { CheckIcon } from "@radix-ui/react-icons"
import React from "react"
import { cn } from "../../../utils/formatting"
import { FormControlLabel } from "@mui/material";
import { DeleteIcon } from "lucide-react";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";


export const Checkbox = React.forwardRef(({ className, ...props }, ref) => (
  <CheckboxPrimitive.Root
    ref={ref}
    className={cn(
      "peer h-4 w-4 shrink-0 rounded-sm border border-primary shadow focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:cursor-not-allowed disabled:opacity-50 data-[state=checked]:bg-primary data-[state=checked]:text-primary-foreground",
      className
    )}
    {...props}
  >
    <CheckboxPrimitive.Indicator
      className={cn("flex items-center justify-center text-current")}
    >
      <CheckIcon className="h-4 w-4" />
    </CheckboxPrimitive.Indicator>
  </CheckboxPrimitive.Root>
))
Checkbox.displayName = CheckboxPrimitive.Root.displayName


export const ChkInputBox = ({
  onChange,
  placeholder,
  width,
}) => {
  return (
    <FormControlLabel
      style={{
        marginLeft: "10px",
        marginRight: "10px",
        marginTop: "1.8rem",
        marginBottom: "0.8rem",
        color: "#979797",
        fontSize: "0.5rem",
        width: width ? `${width}%` : "50%",
      }}
      control={
        <Checkbox
          onChange={(e) => onChange(e.target.value)}
          sx={{
            color: "#dc2342",
            "&.Mui-checked": {
              color: "#dc2342",
            },
          }}
        />
      }
      label={placeholder}
    />
  );
};

export const ChkInputBoxMini = ({
  onChange,
  placeholder,
  borderColor,
  checked,
  width,
}) => {
  return (
    <FormControlLabel
      style={{
        color: "#979797",
        width: width ? width : "auto",
      }}
      control={
        <Checkbox
          checked={checked}
          onChange={(e) => onChange(e.target.value)}
          sx={{
            color: borderColor ? borderColor : "#dc2342",
            "&.Mui-checked": {
              color: "#dc2342",
            },
          }}
        />
      }
      label={placeholder}
    />
  );
};

export const ChkInputButton = ({
  onChange,
  placeholder,
}) => {
  return (
    <FormControlLabel
      style={{
        marginLeft: "10px",
        marginRight: "30px",
        marginTop: "1.8rem",
        marginBottom: "0.8rem",
        background: "#f3f0f0",
        borderRadius: "5px",
      }}
      labelPlacement="end"
      control={
        <Checkbox
          defaultChecked
          icon={<RadioButtonCheckedIcon />}
          checkedIcon={<RadioButtonUncheckedIcon />}
          onChange={(e) => onChange(e.target.value)}
          sx={{
            borderRadius: "10px",
            marginRight: "10px",
            marginLeft: 0,
            color: "#dc2342",
            "&.Mui-checked": {
              color: "#dc2342",
            },
          }}
        />
      }
      label={placeholder}
    />
  );
};

export const DeleteOptionMini = ({
  onChange,
  placeholder,
}) => {
  return (
    <FormControlLabel
      style={{
        color: "#979797",
      }}
      control={
        <Checkbox
          defaultChecked
          icon={<DeleteIcon />}
          checkedIcon={<DeleteIcon />}
          onChange={(e) => onChange(e.target.value)}
          sx={{
            margin: 0,
            color: "#dc2342",
            "&.Mui-checked": {
              color: "#dc2342",
            },
          }}
        />
      }
      label={placeholder}
    />
  );
};


export const TermsAndConditionCheck = ({ tncSet }) => {
  return (
    <div
      className="w-full flex flex-row"
      style={{
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div
        className="w-full flex flex-row"
        style={{
          width: "50px",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <FormControlLabel
          style={{
            marginLeft: "5px",
            marginRight: "5px",
            marginTop: "1.8rem",
            marginBottom: "0.8rem",
            color: "#979797",
            fontSize: "0.5rem",
            width: "10%",
          }}
          control={
            <Checkbox
              onChange={(e) => tncSet(e.target.value)}
              sx={{
                color: "#dc2342",
                "&.Mui-checked": {
                  color: "#dc2342",
                },
              }}
            />
          }
          label={""}
        />
      </div>
    </div>
  );
};
