import axios from "axios"
import { BASE_URL } from "./appConst"

export const signup = async(values) => {

  const userData = {
    email: values.email,
    password: values.password,
  }

  const session = await axios.post(`${BASE_URL}/sign-up`, userData);

  return session.data;

};

export const login = async(values) => {
  const session = await axios.post(`${BASE_URL}/login`, 
    {
      email: values.email,
      password: values.password,
    }
  );

  const token = session.data.data.response.AccessToken;
  localStorage.setItem("accessToken", token);

  return session.data;
};

export const axiosAuth = () => {

  const token = localStorage.getItem("accessToken");

  const axiosInstance = axios.create({
    baseURL: BASE_URL,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

  return axiosInstance;
};

export const forgotPassword = async (email) => {
  const api = axiosAuth();

  const response = await api.post(`${BASE_URL}/forget-password`, 
    {
      email: email,
    });

  return response.data;
}

export const resetPassword = async ( email, confirmationCode, newPassword ) => {
  const api = axiosAuth();

  const response = await api.post(`${BASE_URL}/reset-password`, {
    email: email,
    confirmationCode: confirmationCode,
    newPassword: newPassword,
  });

  return response.data;
};