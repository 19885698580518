import { CalendarHeart, Cog, HeartPulse, House, LogOut, MessageSquareDot } from "lucide-react";
import { Sheet, SheetContent, SheetHeader, SheetTitle, SheetTrigger } from "../common/sheet";
import Diversity1OutlinedIcon from "@mui/icons-material/Diversity1Outlined";
import { ScrollArea, ScrollBar } from "../scroll-area";
import { ProfilePicture } from "../common/ProfilePicture";
import { MobileNavButton } from "./mobile-sidebar-nav";
import { MobileSidebarButton } from "./mobile-sidebar-button";
import { LogOutDialog } from "../log-out-dialog";
import { useSelector } from "react-redux";
import { selectCustomer } from "../../../store/customerSlice";

export const MobileSidebar = ({ children }) => {

  const customer = useSelector(selectCustomer);

  const sidebarButtons = [
    {
      name: "Home",
      icon: <House />,
      link: "/dashboard",
    },
    {
      name: "Health Report",
      icon: <HeartPulse />,
      link: "/healthreport",
    },
    {
      name: "Counselling",
      icon: <Diversity1OutlinedIcon />,
      link: "/counselling",
    },
    {
      name: "Subscriptions",
      icon: <CalendarHeart />,
      link: "/subscriptions",
    },
    {
      name: "Notifications",
      icon: <MessageSquareDot />,
      link: "/notification",
    },
    {
      name: "My Account",
      icon: <Cog />,
      link: "/accountsettings",
    },
  ];

  return (
    <Sheet>
      <SheetTrigger>{children}</SheetTrigger>
      <SheetContent side="left">
        <SheetHeader>
          <SheetTitle>My Profile</SheetTitle>
        </SheetHeader>
        <hr />
        <ScrollArea className="h-[90dvh]">
          <div className="h-full space-y-4 py-3">
            <section className="flex flex-col items-center py-3">
              <div className="flex flex-col items-center justify-center gap-y-3 ">
                <div className="border-1 border-slate-400 rounded-full">
                  <ProfilePicture className="size-16"/>
                </div>
                <div className="text-lg font-medium">
                  {customer?.first_name || "Loading..."}
                </div>
              </div>
            </section>
            <section className="space-y-2 flex flex-col gap-1">
              {sidebarButtons.map((button) => (
                <MobileNavButton
                  key={button.link}
                  route={button.link}
                  Icon={button.icon}
                  label={button.name}
                />
              ))}
            </section>
            <div className="flex justify-center items-center mt-20">
              <LogOutDialog>
                <MobileSidebarButton>
                  <span>
                    Logout
                    <LogOut />
                  </span>
                </MobileSidebarButton>
              </LogOutDialog>
            </div>
          </div>
          <ScrollBar orientation="vertical" />
        </ScrollArea>
      </SheetContent>
    </Sheet>
  );
};
