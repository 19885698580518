import { useEffect, useState } from "react";
import { getBookedSession } from "../api/customer.api";
import { useSelector } from "react-redux";
import { selectCustomer } from "../store/customerSlice";

export const useFetchIsSessionBooked = () => {

  const [isSessionBooked, setIsSessionBooked] = useState();
  const [isPending, setIsPending] = useState(true);
  const customer = useSelector(selectCustomer);

  useEffect(() => {
    const fetchBookedSession = async () => {
      try {
        if(customer) {
          const response = await getBookedSession(customer.id);

          if (response.message.toLowerCase() === "success") {
            setIsSessionBooked(response.data.sessions.length > 0);
          }
        }
      } catch (error) {
        console.error(error, "Couldn't get session slots");
      } finally {
        setIsPending(false);
      }
    };

    fetchBookedSession();
  }, [customer]);

  return { isSessionBooked, isPending };
}