import { useFetchSessionDates } from "../../../hooks/useFetchSessionDates";
import Calendar from "../common/Calendar"

export default function DesktopCalendar ({selectedDay, onSelect}) {

  const sessionDates = useFetchSessionDates();

  return (
    <Calendar
      fromDate={new Date(new Date().setDate(new Date().getDate() + 1))}
      toYear={new Date().getFullYear() + 1}
      className="rounded-md text-lg font-light text-zinc-700"
      mode="single"
      styles={{
        CaptionLabel: {
          fontWeight: 400,
        },
        Day: {
          paddingLeft: "0.45rem",
          paddingRight: "0.45rem",
        },
      }}
      modifiers={{
        sessionDate: sessionDates,
      }}
      modifiersClassNames={{
        sessionDate:
          "!text-white !font-semibold border-2 border-[#274760] bg-[#274760] rounded-lg",
        today: "!bg-[#307bc4]/40 !text-black",
      }}
      selected={selectedDay}
      onSelect={onSelect}
    />
  );
}