import React from 'react'

const PaymentSuccessLoader = () => {
  return (
    <>
      <div className="text-center">
        <div className="relative inline-block p-3 rounded-full border-2 border-green-600 animate-border-animate">
          <svg
            className="check-icon animate-draw-check text-green-600"
            xmlns="http://www.w3.org/2000/svg"
            width="80"
            height="80"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          >
            <path d="M5 13l4 4L19 7" />
          </svg>
        </div>
        <h4 className="mt-2 text-green-600">Payment Completed Successfully.</h4>
      </div>
      <style jsx>{`
        .check-icon {
          stroke-dasharray: 100;
          stroke-dashoffset: 100;
          animation: draw-check 1.5s ease-out forwards 1s;
        }

        @keyframes draw-check {
          from {
            stroke-dashoffset: 100;
          }
          to {
            stroke-dashoffset: 0;
          }
        }
      `}</style>
    </>
  );
}

export default PaymentSuccessLoader