import { EyeIcon, EyeOffIcon } from "lucide-react";
import React, { useState } from "react";



export const PasswordToggleWrapper = ({
  children,
}) => {
  const [eyeOn, setEyeOn] = useState(false);

  const handleClick = () => {
    setEyeOn((prev) => !prev);
  };

  return (
    <div className="relative">
      {React.Children.map(children, (child) => {

        return React.cloneElement(child, {
          type: eyeOn ? "text" : "password",
        });
      })}
      <div
        className="absolute right-2 top-1/2 -translate-y-1/2 cursor-pointer text-[#888891]"
        onClick={handleClick}
      >
        {eyeOn ? <EyeIcon /> : <EyeOffIcon />}
      </div>
    </div>
  );
};
