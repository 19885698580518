export const monthlyplans1 = [
  {
    service: "type1",
    price: "One member - Rs. 499/-",
    subtitle: "(GST and Payment Gateway fees Extra).",
    member: 1,
    duration: 3,
    perks: {
      perk1: "monthly wellness assessment",
      perk2: "BP check",
      perk3: "RBS",
      perk4: "counselling",
    },
  },
  {
    service: "type1",
    price: "Two members - Rs. 949/-",
    member: 2,
    duration: 3,
    subtitle: "(GST and Payment Gateway fees Extra).",
    perks: {
      perk1: "monthly wellness assessment",
      perk2: "BP check",
      perk3: "RBS",
      perk4: "counselling",
    },
  },
  {
    service: "type1",
    price: "Three members - Rs. 1399/-",
    member: 3,
    duration: 3,
    subtitle: "(GST and Payment Gateway fees Extra).",
    perks: {
      perk1: "monthly wellness assessment",
      perk2: "BP check",
      perk3: "RBS",
      perk4: "counselling",
    },
  },
  {
    service: "type1",
    price: "Four members - Rs. 1849/-",
    member: 4,
    duration: 3,
    subtitle: "(GST and Payment Gateway fees Extra).",
    perks: {
      perk1: "monthly wellness assessment",
      perk2: "BP check",
      perk3: "RBS",
      perk4: "counselling",
    },
  },
  {
    service: "type1",
    price: "Five members - Rs. 2299/-",
    member: 5,
    duration: 3,
    subtitle: "(GST and Payment Gateway fees Extra).",
    perks: {
      perk1: "monthly wellness assessment",
      perk2: "BP check",
      perk3: "RBS",
      perk4: "counselling",
    },
  },
];

export const yearlyplans1 = [
  {
    service: "type1",
    price: "One member - Rs. 1899/-",
    member: 1,
    duration: 12,
    subtitle: "(GST and Payment Gateway fees Extra).",
    perks: {
      perk1: "monthly wellness assessment",
      perk2: "BP check",
      perk3: "RBS",
      perk4: "counselling",
    },
  },
  {
    service: "type1",
    price: "Two members - Rs. 3699/-",
    member: 2,
    duration: 12,
    subtitle: "(GST and Payment Gateway fees Extra).",
    perks: {
      perk1: "monthly wellness assessment",
      perk2: "BP check",
      perk3: "RBS",
      perk4: "counselling",
    },
  },
  {
    service: "type1",
    price: "Three members - Rs. 5499/-",
    member: 3,
    duration: 12,
    subtitle: "(GST and Payment Gateway fees Extra).",
    perks: {
      perk1: "monthly wellness assessment",
      perk2: "BP check",
      perk3: "RBS",
      perk4: "counselling",
    },
  },
  {
    service: "type1",
    price: "Four members - Rs. 7299/-",
    member: 4,
    duration: 12,
    subtitle: "(GST and Payment Gateway fees Extra).",
    perks: {
      perk1: "monthly wellness assessment",
      perk2: "BP check",
      perk3: "RBS",
      perk4: "counselling",
    },
  },
  {
    service: "type1",
    price: "Five members - Rs. 9099/-",
    member: 5,
    duration: 12,
    subtitle: "(GST and Payment Gateway fees Extra).",
    perks: {
      perk1: "monthly wellness assessment",
      perk2: "BP check",
      perk3: "RBS",
      perk4: "counselling",
    },
  },
];

export const monthlyplans2 = [
  {
    service: "type2",
    price: "One member - Rs. 12499/-",
    member: 1,
    duration: 3,
    subtitle: "(GST and Payment Gateway fees Extra).",
    perks: {
      perk1: "monthly wellness assessment",
      perk2: "BP check",
      perk3: "RBS",
      perk4: "counselling",
      perk5: "Wellness Products with 15 days moneyback guaranty.",
    },
  },
  {
    service: "type2",
    price: "Two members - Rs. 21949/-",
    member: 2,
    duration: 3,
    subtitle: "(GST and Payment Gateway fees Extra).",
    perks: {
      perk1: "monthly wellness assessment",
      perk2: "BP check",
      perk3: "RBS",
      perk4: "counselling",
      perk5: "Wellness Products with 15 days moneyback guaranty.",
    },
  },
  {
    service: "type2",
    price: "Three members - Rs. 25399/-",
    member: 3,
    duration: 3,
    subtitle: "(GST and Payment Gateway fees Extra).",
    perks: {
      perk1: "monthly wellness assessment",
      perk2: "BP check",
      perk3: "RBS",
      perk4: "counselling",
      perk5: "Wellness Products with 15 days moneyback guaranty.",
    },
  },
  {
    service: "type2",
    price: "Four members - Rs. 31849/-",
    member: 4,
    duration: 3,
    subtitle: "(GST and Payment Gateway fees Extra).",
    perks: {
      perk1: "monthly wellness assessment",
      perk2: "BP check",
      perk3: "RBS",
      perk4: "counselling",
      perk5: "Wellness Products with 15 days moneyback guaranty.",
    },
  },
  {
    service: "type2",
    price: "Five members - Rs. 38299/-",
    member: 5,
    duration: 3,
    subtitle: "(GST and Payment Gateway fees Extra).",
    perks: {
      perk1: "monthly wellness assessment",
      perk2: "BP check",
      perk3: "RBS",
      perk4: "counselling",
      perk5: "Wellness Products with 15 days moneyback guaranty.",
    },
  },
];

export const yearlyplans2 = [
  {
    service: "type2",
    price: "One member - Rs. 49899/-",
    member: 1,
    duration: 12,
    subtitle: "(GST and Payment Gateway fees Extra).",
    perks: {
      perk1: "monthly wellness assessment",
      perk2: "BP check",
      perk3: "RBS",
      perk4: "counselling",
      perk5: "Wellness Products with 15 days moneyback guaranty.",
    },
  },
  {
    service: "type2",
    price: "Two members - Rs. 87699/-",
    member: 2,
    duration: 12,
    subtitle: "(GST and Payment Gateway fees Extra).",
    perks: {
      perk1: "monthly wellness assessment",
      perk2: "BP check",
      perk3: "RBS",
      perk4: "counselling",
      perk5: "Wellness Products with 15 days moneyback guaranty.",
    },
  },
  {
    service: "type2",
    price: "Three members - Rs. 127299/-",
    member: 3,
    duration: 12,
    subtitle: "(GST and Payment Gateway fees Extra).",
    perks: {
      perk1: "monthly wellness assessment",
      perk2: "BP check",
      perk3: "RBS",
      perk4: "counselling",
      perk5: "Wellness Products with 15 days moneyback guaranty.",
    },
  },
  {
    service: "type2",
    price: "Four members - Rs. 101499/-",
    member: 4,
    duration: 12,
    subtitle: "(GST and Payment Gateway fees Extra).",
    perks: {
      perk1: "monthly wellness assessment",
      perk2: "BP check",
      perk3: "RBS",
      perk4: "counselling",
      perk5: "Wellness Products with 15 days moneyback guaranty.",
    },
  },
  {
    service: "type2",
    price: "Five members - Rs. 152999/-",
    member: 5,
    duration: 12,
    subtitle: "(GST and Payment Gateway fees Extra).",
    perks: {
      perk1: "monthly wellness assessment",
      perk2: "BP check",
      perk3: "RBS",
      perk4: "counselling",
      perk5: "Wellness Products with 15 days moneyback guaranty.",
    },
  },
];

export const allPlans = [
  {
    id: 1,
    service: "type1",
    price: "One member - Rs. 499/-",
    subtitle: "(GST and Payment Gateway fees Extra).",
    member: 1,
    duration: 3,
    perks: {
      perk1: "monthly wellness assessment",
      perk2: "BP check",
      perk3: "RBS",
      perk4: "counselling",
    },
  },
  {
    id: 2,
    service: "type1",
    price: "Two members - Rs. 949/-",
    member: 2,
    duration: 3,
    subtitle: "(GST and Payment Gateway fees Extra).",
    perks: {
      perk1: "monthly wellness assessment",
      perk2: "BP check",
      perk3: "RBS",
      perk4: "counselling",
    },
  },
  {
    id: 3,
    service: "type1",
    price: "Three members - Rs. 1399/-",
    member: 3,
    duration: 3,
    subtitle: "(GST and Payment Gateway fees Extra).",
    perks: {
      perk1: "monthly wellness assessment",
      perk2: "BP check",
      perk3: "RBS",
      perk4: "counselling",
    },
  },
  {
    id: 4,
    service: "type1",
    price: "Four members - Rs. 1849/-",
    member: 4,
    duration: 3,
    subtitle: "(GST and Payment Gateway fees Extra).",
    perks: {
      perk1: "monthly wellness assessment",
      perk2: "BP check",
      perk3: "RBS",
      perk4: "counselling",
    },
  },
  {
    id: 5,
    service: "type1",
    price: "Five members - Rs. 2299/-",
    member: 5,
    duration: 3,
    subtitle: "(GST and Payment Gateway fees Extra).",
    perks: {
      perk1: "monthly wellness assessment",
      perk2: "BP check",
      perk3: "RBS",
      perk4: "counselling",
    },
  },
  {
    id: 6,
    service: "type1",
    price: "One member - Rs. 1899/-",
    member: 1,
    duration: 12,
    subtitle: "(GST and Payment Gateway fees Extra).",
    perks: {
      perk1: "monthly wellness assessment",
      perk2: "BP check",
      perk3: "RBS",
      perk4: "counselling",
    },
  },
  {
    id: 7,
    service: "type1",
    price: "Two members - Rs. 3699/-",
    member: 2,
    duration: 12,
    subtitle: "(GST and Payment Gateway fees Extra).",
    perks: {
      perk1: "monthly wellness assessment",
      perk2: "BP check",
      perk3: "RBS",
      perk4: "counselling",
    },
  },
  {
    id: 8,
    service: "type1",
    price: "Three members - Rs. 5499/-",
    member: 3,
    duration: 12,
    subtitle: "(GST and Payment Gateway fees Extra).",
    perks: {
      perk1: "monthly wellness assessment",
      perk2: "BP check",
      perk3: "RBS",
      perk4: "counselling",
    },
  },
  {
    id: 9,
    service: "type1",
    price: "Four members - Rs. 7299/-",
    member: 4,
    duration: 12,
    subtitle: "(GST and Payment Gateway fees Extra).",
    perks: {
      perk1: "monthly wellness assessment",
      perk2: "BP check",
      perk3: "RBS",
      perk4: "counselling",
    },
  },
  {
    id: 10,
    service: "type1",
    price: "Five members - Rs. 9099/-",
    member: 5,
    duration: 12,
    subtitle: "(GST and Payment Gateway fees Extra).",
    perks: {
      perk1: "monthly wellness assessment",
      perk2: "BP check",
      perk3: "RBS",
      perk4: "counselling",
    },
  },
  {
    id: 11,
    service: "type2",
    price: "One member - Rs. 49899/-",
    member: 1,
    duration: 12,
    subtitle: "(GST and Payment Gateway fees Extra).",
    perks: {
      perk1: "monthly wellness assessment",
      perk2: "BP check",
      perk3: "RBS",
      perk4: "counselling",
      perk5: "Wellness Products with 15 days moneyback guaranty.",
    },
  },
  {
    id: 12,
    service: "type2",
    price: "Two members - Rs. 87699/-",
    member: 2,
    duration: 12,
    subtitle: "(GST and Payment Gateway fees Extra).",
    perks: {
      perk1: "monthly wellness assessment",
      perk2: "BP check",
      perk3: "RBS",
      perk4: "counselling",
      perk5: "Wellness Products with 15 days moneyback guaranty.",
    },
  },
  {
    id: 13,
    service: "type2",
    price: "Three members - Rs. 127299/-",
    member: 3,
    duration: 12,
    subtitle: "(GST and Payment Gateway fees Extra).",
    perks: {
      perk1: "monthly wellness assessment",
      perk2: "BP check",
      perk3: "RBS",
      perk4: "counselling",
      perk5: "Wellness Products with 15 days moneyback guaranty.",
    },
  },
  {
    id: 14,
    service: "type2",
    price: "Four members - Rs. 101499/-",
    member: 4,
    duration: 12,
    subtitle: "(GST and Payment Gateway fees Extra).",
    perks: {
      perk1: "monthly wellness assessment",
      perk2: "BP check",
      perk3: "RBS",
      perk4: "counselling",
      perk5: "Wellness Products with 15 days moneyback guaranty.",
    },
  },
  {
    id: 15,
    service: "type2",
    price: "Five members - Rs. 152999/-",
    member: 5,
    duration: 12,
    subtitle: "(GST and Payment Gateway fees Extra).",
    perks: {
      perk1: "monthly wellness assessment",
      perk2: "BP check",
      perk3: "RBS",
      perk4: "counselling",
      perk5: "Wellness Products with 15 days moneyback guaranty.",
    },
  },
  {
    id: 16,
    service: "type2",
    price: "One member - Rs. 12499/-",
    member: 1,
    duration: 3,
    subtitle: "(GST and Payment Gateway fees Extra).",
    perks: {
      perk1: "monthly wellness assessment",
      perk2: "BP check",
      perk3: "RBS",
      perk4: "counselling",
      perk5: "Wellness Products with 15 days moneyback guaranty.",
    },
  },
  {
    id: 17,
    service: "type2",
    price: "Two members - Rs. 21949/-",
    member: 2,
    duration: 3,
    subtitle: "(GST and Payment Gateway fees Extra).",
    perks: {
      perk1: "monthly wellness assessment",
      perk2: "BP check",
      perk3: "RBS",
      perk4: "counselling",
      perk5: "Wellness Products with 15 days moneyback guaranty.",
    },
  },
  {
    id: 18,
    service: "type2",
    price: "Three members - Rs. 25399/-",
    member: 3,
    duration: 3,
    subtitle: "(GST and Payment Gateway fees Extra).",
    perks: {
      perk1: "monthly wellness assessment",
      perk2: "BP check",
      perk3: "RBS",
      perk4: "counselling",
      perk5: "Wellness Products with 15 days moneyback guaranty.",
    },
  },
  {
    id: 19,
    service: "type2",
    price: "Four members - Rs. 31849/-",
    member: 4,
    duration: 3,
    subtitle: "(GST and Payment Gateway fees Extra).",
    perks: {
      perk1: "monthly wellness assessment",
      perk2: "BP check",
      perk3: "RBS",
      perk4: "counselling",
      perk5: "Wellness Products with 15 days moneyback guaranty.",
    },
  },
  {
    id: 20,
    service: "type2",
    price: "Five members - Rs. 38299/-",
    member: 5,
    duration: 3,
    subtitle: "(GST and Payment Gateway fees Extra).",
    perks: {
      perk1: "monthly wellness assessment",
      perk2: "BP check",
      perk3: "RBS",
      perk4: "counselling",
      perk5: "Wellness Products with 15 days moneyback guaranty.",
    },
  },
];
