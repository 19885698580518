import { useForm } from "react-hook-form";
import { Form, FormControl, FormField, FormItem, FormMessage } from "../ui/form/form";
import { useState } from "react";
import { PasswordToggleWrapper } from "../ui/password-toggle-wrapper";
import { FancyInput, Input } from "../ui/input/input";
import { Button } from "../ui/input/Button";
import { ResetPasswordSchema } from "../../schemas/reset-password";
import { zodResolver } from "@hookform/resolvers/zod";
import { resetPassword } from "../../api/auth.api";
import { toast } from "sonner";
import { Loader2 } from "lucide-react";
import { useNavigate } from "react-router-dom";


export default function ResetPassword () {

  const navigate = useNavigate();
  const form = useForm({ resolver: zodResolver(ResetPasswordSchema) });
  const [isPending, setIsPending] = useState(false);

  const onSubmit = async(values) => {
    setIsPending(true);
    const confirmationCode = `${values.code0}${values.code1}${values.code2}${values.code3}${values.code4}${values.code5}`;
    const email = values.email;
    const newPassword = values.newpassword;

    try {
      const response = await resetPassword(
        email,
        confirmationCode,
        newPassword
      );

      if(response.message.toLowerCase() === "success") {
        toast.success("Password Reset Successfully!");
        navigate('/signup')
      }
    } catch (error) {
      toast.error("Failed to Reset Password!");
      console.error(error);
    } finally {
      setIsPending(false);
    }
  };

  return (
    <div
      className="min-h-[100vh] w-full bg-cover bg-center flex items-center justify-center"
      style={{ backgroundImage: "url('/images/nhwm/bg_cover.jpg')" }}
    >
      <div className="relative flex flex-col justify-center items-center bg-white/30 backdrop-blur-md border border-white/20 shadow-lg shadow-gray-400 rounded-lg p-6 w-[95%] md:max-w-[600px] min-h-[60vh] overflow-hidden">
        <div className="absolute top-[-20%] left-[-20%] w-[300px] h-[300px] bg-gradient-to-br from-[#ffffff] to-[#307bc4]/30 rounded-full blur-3xl"></div>

        <div className="mt-8 w-full md:w-[70%]">
          <Form {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)}>
              <div className="flex flex-col gap-4">
                <h3 className="text-center mb-4">Reset Your Password</h3>
                <div>
                  <p className="text-[#86868b] text-center mb-1 text-sm">Verification Code</p>
                  <div className="flex gap-2 justify-center">
                    {[...Array(6)].map((_, index) => (
                      <FormField
                        key={index}
                        control={form.control}
                        name={`code${index}`}
                        render={({ field }) => (
                          <FormItem>
                            <FormControl>
                              <Input
                                {...field}
                                className="size-10 text-center text-lg text-zinc-500 border-1 border-zinc-500 rounded-md focus:outline-none focus:ring-2 focus:ring-[#307bc4]"
                                onChange={(e) => {
                                  field.onChange(e);
                                  const nextSibling = document.querySelector(
                                    `input[name=code${index + 1}]`
                                  );
                                  if (nextSibling && e.target.value) {
                                    nextSibling.focus();
                                  }
                                }}
                              />
                            </FormControl>
                          </FormItem>
                        )}
                      />
                    ))}
                  </div>
                </div>
                <div>
                  <FormField
                    control={form.control}
                    name="email"
                    render={({ field }) => (
                      <FormItem>
                        <FormControl>
                          <FancyInput
                            label="Email"
                            error={form.formState.errors.password}
                            {...field}
                          />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </div>
                <div>
                  <FormField
                    control={form.control}
                    name="newpassword"
                    render={({ field }) => (
                      <FormItem>
                        <FormControl>
                          <PasswordToggleWrapper>
                            <FancyInput
                              label="New Password"
                              error={form.formState.errors.password}
                              {...field}
                            />
                          </PasswordToggleWrapper>
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </div>
              </div>
              <div className="flex mt-12 justify-center">
                <Button
                  className="w-[80%]"
                  isPending={isPending}
                  disabled={isPending}
                >
                  {isPending ? (<Loader2/>) : "Reset Password"}
                </Button>
              </div>
            </form>
          </Form>
        </div>
      </div>
    </div>
  );
}
