import React from 'react'

const HealthReportLayout = () => {
    const data = [
      { gender: "Male", age: 25, bloodGroup: "A+", weight: 70, bloodPressure: "120/80", sugarlevel: 140, medication: "Sugar Tablet", others: "Nothing"},
    ];

    return (
      <>
        <div className="max-w-4xl mx-auto p-4">
          <table className="table-auto w-full border-collapse border border-gray-300 text-left text-gray-700">
            <thead>
              <tr>
                <th className="border border-gray-300 px-4 py-2 text-center">Gender</th>
                <th className="border border-gray-300 px-4 py-2 text-center">Age</th>
                <th className="border border-gray-300 px-4 py-2 text-center">
                  Blood Group
                </th>
                <th className="border border-gray-300 px-4 py-2 text-center">
                  Weight (kg)
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="border border-gray-300 px-4 py-2 text-center">
                  {data[0].gender}
                </td>
                <td className="border border-gray-300 px-4 py-2 text-center">
                  {data[0].age}
                </td>
                <td className="border border-gray-300 px-4 py-2 text-center">
                  {data[0].bloodGroup}
                </td>
                <td className="border border-gray-300 px-4 py-2 text-center">
                  {data[0].weight}
                </td>
              </tr>
            </tbody>
          </table>
          <table className="table-auto w-full border-collapse border border-gray-300 text-left text-gray-700 mt-4">
            <thead>
              <tr>
                <th className="border border-gray-300 px-4 py-2 text-center">
                  Blood Pressure
                </th>
                <th className="border border-gray-300 px-4 py-2 text-center">
                  Sugar Level
                </th>
                <th className="border border-gray-300 px-4 py-2 text-center">
                  Medications
                </th>
                <th className="border border-gray-300 px-4 py-2 text-center">Others</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="border border-gray-300 px-4 py-2 text-center">
                  {data[0].bloodPressure}
                </td>
                <td className="border border-gray-300 px-4 py-2 text-center">
                  {data[0].sugarlevel}
                </td>
                <td className="border border-gray-300 px-4 py-2 text-center">
                  {data[0].medication}
                </td>
                <td className="border border-gray-300 px-4 py-2 text-center">
                  {data[0].others}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </>
    );
}

export default HealthReportLayout