import { Slot } from "@radix-ui/react-slot"
import React from "react"
import { cn } from "../../../utils/formatting"
import { cva } from "class-variance-authority";

const buttonVariants = cva(
  "inline-flex items-center justify-center whitespace-nowrap rounded-lg focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50 font-semibold transition-all duration-500 ease-in-out brightness-90",
  {
    variants: {
      variant: {
        default:
          "text-white bg-gradient-to-br from-[#307bc4] to-[#274760] hover:from-[#274760] hover:to-[#307bc4] hover:brightness-100 shadow-md",
        destructive: "bg-red-500 text-white shadow-sm hover:brightness-90",
        outline:
          "border-1 border-[#307bc4] shadow-sm hover:bg-[#307bc4]/20 text-[#274760]",
        secondary:
          "bg-secondary text-secondary-foreground shadow-sm hover:brightness-80",
        ghost: "hover:bg-[#307bc4] hover:text-accent-foreground",
        link: "text-primary underline-offset-4 hover:underline",
      },
      size: {
        default: "px-3 py-2 h-fit w-fit",
        sm: "h-8 rounded-md px-3 text-xs",
        lg: "h-10 rounded-md px-8",
        icon: "h-9 w-9",
      },
    },
    defaultVariants: {
      variant: "default",
      size: "default",
    },
  }
);

const Button = React.forwardRef(
  ({ className, variant, size, asChild = false, ...props }, ref) => {
    const Comp = asChild ? Slot : "button"
    return (
      <Comp
        className={cn(buttonVariants({variant, size, className}))}
        ref={ref}
        {...props}
      />
    );
  }
)
Button.displayName = "Button"

export { Button, buttonVariants }; 