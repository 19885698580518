import { useEffect, useState } from "react";
import { useFetchFreeSessions } from "./useFetchFreeSessions";

export const useFetchSessionDates = () => {

  const [sesstionDates, setSessionDates] = useState();

  const {freeSessions } = useFetchFreeSessions();

  useEffect(() => {
    if (freeSessions) {
      const dates = freeSessions.map((session) => new Date(session.date));

      setSessionDates(dates);
    }
  }, [freeSessions])

  return sesstionDates;
}