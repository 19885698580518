import { z } from "zod";

export const ResetPasswordSchema = z.object({
  email: z
    .string()
    .email()
    .regex(
      /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,63}$/,
      "Enter a valid Email."
    ),
  newpassword: z
    .string()
    .min(8, "Password must be at least 8 characters long")
    .max(64, "Password must not exceed 64 characters")
    .regex(
      /(.*[A-Z].*[A-Z])/,
      "Password must contain at least 2 uppercase letters"
    )
    .regex(
      /(.*[a-z].*[a-z])/,
      "Password must contain at least 2 lowercase letters"
    )
    .regex(/(.*\d.*\d)/, "Password must contain at least 2 numbers")
    .regex(
      /(.*[@$!%*?&#].*[@$!%*?&#])/,
      "Password must contain at least 2 special characters (@$!%*?&)"
    ),
  code0: z
    .string()
    .length(1)
    .max(1)
    .regex(/^\d{1}$/),
  code1: z
    .string()
    .length(1)
    .max(1)
    .regex(/^\d{1}$/),
  code2: z
    .string()
    .length(1)
    .max(1)
    .regex(/^\d{1}$/),
  code3: z
    .string()
    .length(1)
    .max(1)
    .regex(/^\d{1}$/),
  code4: z
    .string()
    .length(1)
    .max(1)
    .regex(/^\d{1}$/),
  code5: z
    .string()
    .length(1)
    .max(1)
    .regex(/^\d{1}$/),
});