import { Checkbox } from "./input/Checkbox";


export const TermsAgreement = ({
  checked,
  onCheckedChange,
}) => {
  return (
    <div className="flex items-center gap-3">
      <Checkbox
        checked={checked}
        onCheckedChange={onCheckedChange}
        className="size-5"
      />
      <span className="text-sm md:text-base">
        I agree to{" "}
        <a href="/terms" className="text-primary hover:underline">
          Terms and Conditions
        </a>
      </span>
    </div>
  );
};
