import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from ".";
import { formatDate } from "../../utils/formatting";


export const RegistrationReviewDialog = ({
  children,
  customerDetails,
  onClick,
  isPending,
}) => {

  return (
    <Dialog>
      <DialogTrigger>{children}</DialogTrigger>
      <DialogContent className="bg-[#e1f0ff]">
        <DialogHeader>
          <DialogTitle>Review Your Details</DialogTitle>
        </DialogHeader>
        {customerDetails && (
          <div className="overflow-auto">
            <div className="max-w-[80%] w-fit mx-auto">
              <h5>
                <span className="font-semibold mr-3 text-[#274760]">
                  First Name:
                </span>{" "}
                <span>
                  {customerDetails.first_name ? (
                    customerDetails.first_name
                  ) : (
                    <span className="text-red-500">Required</span>
                  )}
                </span>
              </h5>
              <h5>
                <span className="font-semibold mr-3 text-[#274760]">
                  Middle Name:
                </span>{" "}
                <span>{customerDetails.middle_name}</span>
              </h5>
              <h5>
                <span className="font-semibold mr-3 text-[#274760]">
                  Last Name:
                </span>{" "}
                <span>
                  {customerDetails.last_name ? (
                    customerDetails.last_name
                  ) : (
                    <span className="text-red-500">Required</span>
                  )}
                </span>
              </h5>
              <h5>
                <span className="font-semibold mr-3 text-[#274760]">
                  Date of Birth:{" "}
                </span>{" "}
                <span>{formatDate(customerDetails?.date_of_birth)}</span>
              </h5>
              <h5>
                <span className="font-semibold mr-3 text-[#274760]">
                  Gender:
                </span>{" "}
                <span>
                  {customerDetails.gender ? (
                    customerDetails.gender
                  ) : (
                    <span className="text-red-500">Required</span>
                  )}
                </span>
              </h5>
              <h5>
                <span className="font-semibold mr-3 text-[#274760]">
                  Phone Number:
                </span>{" "}
                <span>
                  {customerDetails.phone_no ? (
                    customerDetails.phone_no
                  ) : (
                    <span className="text-red-500">Required</span>
                  )}
                </span>
              </h5>
              {customerDetails.whatsapp_number && (
                <h5>
                  <span className="font-semibold mr-3 text-[#274760]">
                    WhatsApp Number:
                  </span>{" "}
                  <span>{customerDetails.whatsapp_number}</span>
                </h5>
              )}
              <h5>
                <span className="font-semibold mr-3 text-[#274760]">
                  Email:
                </span>{" "}
                <span>
                  {customerDetails.email ? (
                    customerDetails.email
                  ) : (
                    <span className="text-red-500">Required</span>
                  )}
                </span>
              </h5>
              <h5>
                <span className="font-semibold mr-3 text-[#274760]">
                  Address:
                </span>{" "}
                <span>
                  {customerDetails.address ? (
                    customerDetails.address
                  ) : (
                    <span className="text-red-500">Required</span>
                  )}
                </span>
              </h5>
              <h5>
                <span className="font-semibold mr-3 text-[#274760]">
                  State:
                </span>{" "}
                <span>
                  {customerDetails.state ? (
                    customerDetails.state
                  ) : (
                    <span className="text-red-500">Required</span>
                  )}
                </span>
              </h5>
              <h5>
                <span className="font-semibold mr-3 text-[#274760]">City:</span>{" "}
                <span>
                  {customerDetails.city ? (
                    customerDetails.city
                  ) : (
                    <span className="text-red-500">Required</span>
                  )}
                </span>
              </h5>
              <h5>
                <span className="font-semibold mr-3 text-[#274760]">
                  Pincode:
                </span>{" "}
                <span>
                  {customerDetails.pin ? (
                    customerDetails.pin
                  ) : (
                    <span className="text-red-500">Required</span>
                  )}
                </span>
              </h5>
            </div>
          </div>
        )}
        <div className="flex justify-between space-y-1">
          <DialogClose>
            <div className="cs_btn cs_style_1">
              <span>Edit</span>
            </div>
          </DialogClose>
          <DialogClose>
            <button
              className="cs_btn cs_style_1"
              onClick={onClick}
              disabled={isPending}
            >
              <span>{isPending ? "Registering..." : "Register"}</span>
              <i>
                <img src="/images/icons/arrow_white.svg" alt="Icon" />
                <img src="/images/icons/arrow_white.svg" alt="Icon" />
              </i>
            </button>
          </DialogClose>
        </div>
      </DialogContent>
    </Dialog>
  );
};
