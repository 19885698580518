import clsx from "clsx";
import { twMerge } from "tailwind-merge";

export function cn(...inputs) {
  return twMerge(clsx(inputs));
}

export const formatDate = (date) => {
  if(!date) {
    return;
  }
  const options = { day: '2-digit', month: 'short', year: 'numeric' };
  return new Intl.DateTimeFormat('en-IN', options).format(new Date(date));
};

export const reformatTime = (originalDate) => {
  const dateParts = originalDate.split("-"); // Split the date string by '-'
  return `${dateParts[1]}-${dateParts[2]}-${dateParts[0]}`; // Rearrange the parts
}