import React from 'react'
import { Button } from '../../../ui/input/Button';
import PaymentFaildedLoader from '../../../ui/common/payment-failed-loader';

const PaymentFailed = () => {
  return (
    <div className="w-full h-[90dvh]">
      <div className="p-2 flex justify-center mt-[10rem]">
        <div>
          <div className="flex justify-center items-cente px-6 mb-8">
            <img src="/images/nhwm.png" alt="Logo" className="size-12 mr-3" />
            <span className="font-bold text-2xl flex items-center">NHWM</span>
          </div>
          <div className="border-3 border-red-600 rounded-xl p-4">
            <PaymentFaildedLoader/>
          </div>
          <div className="flex justify-center mt-12">
            <Button
              onClick={() => {
                window.location.href = "/make-payment";
              }}
            >
              Go To Plans
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PaymentFailed