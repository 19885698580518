import React from 'react'

const PaymentFaildedLoader = () => {
  return (
    <>
      <div className="text-center">
        <div className="inline-block p-3 rounded-full border-3 border-red-600">
          <svg
            className='text-red-600'
            xmlns="http://www.w3.org/2000/svg"
            width="80"
            height="80"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="lucide lucide-x"
          >
            <path d="M18 6 6 18" />
            <path d="m6 6 12 12" />
          </svg>
        </div>
        <h4 className="mt-2 text-red-600">Payment Failed!</h4>
      </div>
    </>
  );
}

export default PaymentFaildedLoader