import { Link } from "react-router-dom";
import { cn } from "../../../utils/formatting";

export const MobileNavButton = ({ route, label, Icon }) => {
  return (
    // <Button
    //   asChild
    //   size="lg"
    //   variant="outline"
    //   className={cn(
    //     "w-full justify-start",
    //     window.location.pathname === route ? "bg-secondary" : ""
    //   )}
    // >
    //   <a href={route}>
    //     <div className="flex justify-start items-center font-normal">
    //       <Icon
    //         className={`w-5 h-5 mr-2 group-hover:text-white ${
    //           window.location.pathname === route ? "" : "text-zinc-400"
    //         }`}
    //       />
    //       {label}
    //     </div>
    //   </a>
    // </Button>
    <Link to={route}>
      <div
        className={`border border-[#307bc4] rounded-full px-5 py-3  ${
          window.location.pathname === route
            ? "bg-[#274760] "
            : "hover:bg-[#307bc4]/50"
        }`}
      >
        <h5
          className={`flex gap-2 my-1 ${
            window.location.pathname === route ? "text-white" : ""
          }`}
        >
          {Icon} {label}
        </h5>
      </div>
    </Link>
  );
};
