import { ProfilePicture } from "../common/ProfilePicture";
import { MobileSidebar } from "./mobile-sidebar";


export const MobileHeader = () => {
  return (
    <header className="text-2xl font-semibold font-sans flex items-center justify-between border-0 border-b lg:hidden px-2 py-3">
      <div className="border border-slate-300 rounded-full flex justify-center items-center p-1">
        <MobileSidebar>
          <ProfilePicture />
        </MobileSidebar>
      </div>

      <a className="flex items-center" href="/">
        <img
          src="https://nhwm-job-portal.s3.ap-south-1.amazonaws.com/app-images/logo.png"
          alt="Logo"
          className="size-10 mr-2"
        />
        <span>NHWM</span>
      </a>
    </header>
  );
};
