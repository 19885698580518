import { Link } from "react-router-dom";
import { LogOut } from "lucide-react";
import { useDispatch } from "react-redux";
import { userLogout } from "../../../store/authSlice";
import { DropdownMenu, DropdownMenuContent, DropdownMenuGroup, DropdownMenuItem, DropdownMenuLabel, DropdownMenuSeparator, DropdownMenuTrigger } from "../../Dropdown";


export const ProfileDropdown = ({ children, name }) => {

  const dispatch = useDispatch();

  return (
    <DropdownMenu>
      <DropdownMenuTrigger>{children}</DropdownMenuTrigger>
      <DropdownMenuContent className="mr-3 w-56 bg-white">
        <DropdownMenuLabel><span className="capitalize">{name}'s</span> Account</DropdownMenuLabel>
        <DropdownMenuSeparator />
        <DropdownMenuGroup>
          <DropdownMenuItem>
            <Link to="/accountsettings" className="w-full">
              Account Settings
            </Link>
          </DropdownMenuItem>
        </DropdownMenuGroup>
        <DropdownMenuSeparator />
        <DropdownMenuItem>
          <div
            className="flex w-full cursor-pointer items-center gap-x-3 hover:text-[#274760] text-lg"
            onClick={() => {
              window.localStorage.removeItem("accessToken");
              dispatch(userLogout());
              window.location.replace("/");
            }}
          >
            Logout
            <LogOut className="size-4 hover:shadow-xl" />
          </div>
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};
