import React from "react";
import DashboardLayout from "../../ui/desktop/DashboardLayout";
import { useNavigate } from "react-router-dom";
import { Button } from "../../ui/input/Button";

const Counselling = () => {

  const navigate = useNavigate();

  return (
    <DashboardLayout>
      <div className="w-full h-[70dvh] flex flex-col items-center justify-center">
        <h4 className="text-center m-3">Book a Paid Health Checkup to see your Counselling Details</h4>
        <Button
          className=" text-lg font-semibold font-serif h-[5rem] w-fit"
          onClick={() => {
            navigate("/paidsession");
          }}
        >
          Book a Paid Health Checkup
        </Button>
      </div>
    </DashboardLayout>
  );
};

export default Counselling;
