import React from 'react'
import { cn } from '../../../utils/formatting'

const Card = ({children, className, ...props}) => {
  return (
    <div
      className={cn(
        "border-1 border-[#307bc4] rounded-[1rem] min-w-[350px] max-w-fit p-3 h-fit bg-white hover:shadow-lg hover:shadow-[#307bc4]/50",
        className
      )}
      {...props}
    >
      {children}
    </div>
  );
}

export default Card