import React from "react";
import Footer from "../Footer";
import Header from "../Header";
import { Outlet } from "react-router-dom";

export default function Layout() {
  return (
    <>
      <Header logoSrc="/images/nhwm_logo.png" variant="cs_heading_color" />
      <Outlet/>
      <Footer />
    </>
  );
}
