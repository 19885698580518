import React from 'react'
import { cn } from '../../../utils/formatting';
import DesktopSidebarButtons from './DesktopSidebarButtons';

const DesktopSidebar = ({className}) => {
  return (
    <div
      className={cn(
        "flex-col justify-start h-[100vh] w-[340px] py-3 px-2 border-r-2 border-slate-200",
        className
      )}
    >
      <a className="flex items-cente px-6" href="/">
        <img src="/images/nhwm.png" alt="Logo" className="size-12 mr-3" />
        <span className="font-bold text-2xl flex items-center">NHWM</span>
      </a>
      <div className="flex flex-col mt-5 gap-y-3 ">
        <DesktopSidebarButtons />
      </div>
    </div>
  );
}

export default DesktopSidebar