import { useState } from "react";
import { useForm } from "react-hook-form";
import { Form, FormControl, FormField, FormItem, FormMessage } from "../ui/form/form";
import { FancyInput } from "../ui/input/input";
import { Button } from "../ui/input/Button";
import { forgotPassword } from "../../api/auth.api";
import { toast } from "sonner";
import { Loader } from "lucide-react";
import { useNavigate } from "react-router-dom";


export default function ForgotPassword() {
  
  const navigate = useNavigate();
  const form = useForm();
  const [isPending, setIsPending] = useState(false);

  const onSubmit = async (values) => {
    setIsPending(true);
    try {
      const response = await forgotPassword(values.email);

      if(response.message.toLowerCase() === "success") {
        toast.success("Email Sent!");
        navigate("/reset-password");
      }
    } catch (error) {
      toast.error("Failed to send Email!");
      console.error(error);
    } finally {
      setIsPending(false);
    }
  };

  return (
    <div
      className="min-h-[100vh] w-full bg-cover bg-center flex items-center justify-center"
      style={{ backgroundImage: "url('/images/nhwm/bg_cover.jpg')" }}
    >
      <div className="relative flex flex-col justify-center items-center bg-white/30 backdrop-blur-md border border-white/20 shadow-lg shadow-gray-400 rounded-lg p-6 w-[95%] md:max-w-[600px] min-h-[60vh] overflow-hidden">
        <div className="absolute top-[-20%] left-[-20%] w-[300px] h-[300px] bg-gradient-to-br from-[#ffffff] to-[#307bc4]/30 rounded-full blur-3xl"></div>

        <div className="mt-8 w-full md:w-[70%]">
          <Form {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)}>
              <div>
                <h3 className="text-center mb-20">Send Email to Reset Password</h3>
                <div>
                  <FormField
                    control={form.control}
                    name="email"
                    render={({ field }) => (
                      <FormItem>
                        <FormControl>
                          <FancyInput
                            label="Email"
                            error={form.formState.errors.email}
                            {...field}
                          />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </div>
              </div>
              <div className="flex mt-12 justify-center">
                <Button
                  className="w-[80%]"
                  isPending={isPending}
                  disabled={isPending}
                >
                  { isPending ? (<Loader/>) : ("Send Email")}
                </Button>
              </div>
            </form>
          </Form>
        </div>
      </div>
    </div>
  );
}
