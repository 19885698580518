import * as React from "react"
import { cn } from "../../../utils/formatting"


const Input = React.forwardRef(
  ({ className, error, type, ...props }, ref) => {
    return (
      <input
        type={type}
        className={cn(
          "",
          className, error ? "border-[#ff3037] bg-red-500/15" : ""
        )}
        ref={ref}
        {...props}
      />
    )
  }
)
Input.displayName = "Input"

export { Input }

export const FancyInput = React.forwardRef(
  (
    {
      containerClassName,
      inputClassName,
      labelClassName,
      label,
      error,
      ...inputProps
    },
    ref
  ) => {
    return (
      <div
        className={cn(
          "relative h-16 rounded-full border bg-white",
          containerClassName,
          error ? "border-[#ff3037] bg-red-500/15" : "border-zinc-200"
        )}
      >
        <input
          id={inputProps.id || label}
          name={inputProps.name}
          type={inputProps.type}
          className={cn(
            "peer h-full w-full rounded-full bg-transparent px-3 pb-1 pt-[0.9rem] text-sm tracking-wide text-black placeholder-transparent focus:outline-[#307bc4]",
            inputClassName
          )}
          ref={ref}
          placeholder=""
          {...inputProps}
        />
        <label
          htmlFor={inputProps.id || ""}
          className={cn(
            "absolute left-3 top-3 -translate-y-1/2 transform select-none text-xs text-zinc-500 transition-all peer-placeholder-shown:top-1/2 peer-placeholder-shown:text-sm peer-placeholder-shown:text-[#86868b] peer-hover:cursor-text peer-focus:top-3 peer-focus:text-xs peer-focus:text-zinc-500",
            labelClassName,
            error ? "!text-[#ff3037]" : ""
          )}
        >
          {label}
        </label>
      </div>
    );
  }
);
FancyInput.displayName = "FloatingLabel";
