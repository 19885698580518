import React from 'react'
import WelcomeText from '../common/WelcomeText'
import { ProfilePicture } from '../common/ProfilePicture'
import { ProfileDropdown } from '../common/ProfileDropDown'

const DesktopHeader = ({ name }) => {

  return (
    <header className='flex justify-between items-center w-full p-6'>
      <WelcomeText name={name}/>
      <div className='border border-slate-300 rounded-full flex justify-center items-center p-1'>
        <ProfileDropdown name={name}>
          <ProfilePicture/>
        </ProfileDropdown>
      </div>
    </header>
  )
}

export default DesktopHeader