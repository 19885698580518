import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { userLogout } from "../store/authSlice";


const AuthWatcher = () => {
  const dispatch = useDispatch();
  const expiry = useSelector((state) => state.authUser.expiry);

  useEffect(() => {
    if (expiry) {
      const timeout = setTimeout(() => {
        dispatch(userLogout());
      }, expiry - Date.now());

      return () => clearTimeout(timeout);
    }
  }, [expiry, dispatch]);

  return null; 
};

export default AuthWatcher;
