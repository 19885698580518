import React, { useState } from "react";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from "../ui/form/form";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { LoginSchema } from "../../schemas/login";
import { FancyInput } from "../ui/input/input";
import { PasswordToggleWrapper } from "../ui/password-toggle-wrapper";
import { Button } from "../ui/input/Button";
import { Loader2 } from "lucide-react";
import { login } from "../../api/auth.api";
import { toast } from "sonner";
import { userLogin } from "../../store/authSlice";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

export default function LoginForm() {

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const form = useForm({ resolver: zodResolver(LoginSchema) });
  const [isPending, setIsPending] = useState(false);

  const onSubmit = async (values) => {
    try {
      setIsPending(true);
      const session = await login(values);
      if (session.message.toLowerCase() === "success") {
        dispatch(userLogin(session.data.response));
        navigate("/dashboard")
      }
    } catch (error) {
      console.error(error);
      if (error && error.response?.status === 500) {
        toast.error(error.response.data.data.message);
        form.setError("password", {
          message: error.response.data.data.message,
        });
      } else {
        toast.error("Something went wrong, Please try again!");
        console.error(error);
      }
    } finally {
      setIsPending(false);
    }
  };

  return (
    <div>
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <div className="flex flex-col gap-4">
            <div>
              <FormField
                control={form.control}
                name="email"
                render={({ field }) => (
                  <FormItem>
                    <FormControl>
                      <FancyInput
                        label="Email"
                        error={form.formState.errors.email}
                        {...field}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
            <div>
              <FormField
                control={form.control}
                name="password"
                render={({ field }) => (
                  <FormItem>
                    <FormControl>
                      <PasswordToggleWrapper>
                        <FancyInput
                          label="Password"
                          error={form.formState.errors.password}
                          {...field}
                        />
                      </PasswordToggleWrapper>
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
            <div className="flex flex-col gap-3 my-6 items-center">
              <Button
                className="w-[80%] flex justify-center"
                isPending={isPending}
                disabled={isPending}
              >
                {isPending ? <Loader2 className="ml-3 animate-spin" /> : "Login"}
              </Button>
              <a href="/forgot-password" className="text-primary hover:underline">
                Forgot Password?
              </a>
            </div>
          </div>
        </form>
      </Form>
    </div>
  );
}
