import React, { useState } from 'react'
import SignupForm from './signup-form';
import LoginForm from './login-form';

export default function AuthenticationView () {

  const [form, setForm] = useState("signup");
  const onSignup = () => {
    setForm("login");
  };

  const renderView = () => {
    switch (form) {
      case "signup" :
        return (
          <SignupForm onSignup={onSignup}/>
        );
      case "login" : 
        return (<LoginForm/>);
      default :
        break;
    }
  }

  return (
    <div
      className="min-h-[100vh] w-full bg-cover bg-center flex items-center justify-center"
      style={{ backgroundImage: "url('/images/nhwm/bg_cover.jpg')" }}
    >
      <div className="relative flex flex-col justify-center items-center bg-white/30 backdrop-blur-md border border-white/20 shadow-lg shadow-gray-400 rounded-lg p-6 w-[95%] md:max-w-[600px] min-h-[60vh] overflow-hidden">
        <div className="absolute top-[-20%] left-[-20%] w-[300px] h-[300px] bg-gradient-to-br from-[#ffffff] to-[#307bc4]/30 rounded-full blur-3xl"></div>

        <div className="relative p-2 rounded-full w-full md:w-[80%] h-fit border border-[#274760] flex items-center justify-between overflow-hidden gap-4">
          <div
            className={`absolute top-0 left-0 h-full w-1/2 bg-gradient-to-r from-[#307bc4] to-[#1d5f9b] transition-transform duration-500 ease-in-out 
        ${form === "login" ? "translate-x-full" : "translate-x-0"}`}
          ></div>
          <div
            className={`relative p-2 text-center text-lg font-semibold border border-[#274760] rounded-full transition-colors duration-500 w-[12rem] cursor-pointer
        ${form === "signup" ? "text-white" : "text-[#274760]"} 
        ${form === "signup" ? "hover:text-white" : "hover:text-[#307bc4]"}`}
            onClick={() => setForm("signup")}
          >
            Sign Up
          </div>
          <div
            className={`relative p-2 text-center text-lg font-semibold border border-[#274760] rounded-full transition-colors duration-500 w-[12rem] cursor-pointer
        ${form === "login" ? "text-white" : "text-[#274760]"} 
        ${form === "login" ? "hover:text-white" : "hover:text-[#307bc4]"}`}
            onClick={() => setForm("login")}
          >
            Log In
          </div>
        </div>
        <div className="transition-all duration-500 mt-8 w-full md:w-[70%]">{renderView()}</div>
      </div>
    </div>
  );
}
