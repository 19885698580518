
import React from 'react'
import { cn } from '../../../utils/formatting'
import DesktopSidebar from './DesktopSidebar'
import { MobileHeader } from '../mobile/mobile-header'

const DashboardLayout = ({children, className}) => {
  return (
    <div className={cn('flex flex-col lg:flex-row gap-2 min-w-full min-h-[100vh]', className)}>
      <div>
        <MobileHeader/>
        <div className='w-[340px] lg:block hidden'>
          <DesktopSidebar/>
        </div>
      </div>
      
      <div className='h-[90vh] md:h-[100vh] w-full overflow-auto'>
        {children}
      </div>
    </div>
  )
}

export default DashboardLayout