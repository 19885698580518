import React from "react";
import Card from "../../../ui/common/Card";
import DashboardLayout from "../../../ui/desktop/DashboardLayout";
import { Button } from "../../../ui/input/Button";
import { useNavigate } from "react-router-dom";
import { monthlyplans1, monthlyplans2, yearlyplans1, yearlyplans2 } from "../../../../utils/subscribtionPlans";

const PaidSession = () => {
  const navigate = useNavigate();

  const handleSelectplan = (plan) => {
    navigate("/make-payment", { state: { plan } });
  };

  return (
    <DashboardLayout>
      <div>
        <div className="mx-8 my-12">
          <div className="flex flex-col items-center">
            <h3 className="mb-2">Paid Health Check-Up</h3>
            <h5 className="mb-0 text-center">
              Free for one Child below 12 years with any following package.
            </h5>

            <div>
              <div className="my-4">
                <div className=" md:border-l-2 border-[#307bc4] rounded-tl-lg">
                  <div className="bg-[#307bc4] p-3 rounded-lg w-fit my-4 rounded-bl-none">
                    <h4 className="text-white mb-0 w-fit">3 Months Plans</h4>
                  </div>
                  <div className="flex flex-col lg:flex-row gap-8 flex-wrap justify-evenly">
                    {monthlyplans1.map((plan) => (
                      <Card key={plan.price}>
                        <h4>{plan.price}</h4>
                        <p className="text-sm">{plan.subtitle}</p>
                        <ul>
                          <li>{plan.perks.perk1}</li>
                          <li>{plan.perks.perk2}</li>
                          <li>{plan.perks.perk3}</li>
                          <li>{plan.perks.perk4}</li>
                        </ul>
                        <div className="flex justify-center">
                          <Button onClick={() => handleSelectplan(plan)}>
                            Subscribe
                          </Button>
                        </div>
                      </Card>
                    ))}
                  </div>
                </div>
              </div>

              <div className="my-4">
                <div className="md:border-l-2 border-[#307bc4] rounded-tl-lg">
                  <div className="bg-[#307bc4] rounded-lg rounded-bl-none p-3 w-fit my-4">
                    <h4 className="text-white mb-0">Yearly Plans</h4>
                  </div>
                  <div className="flex flex-col lg:flex-row gap-8 flex-wrap justify-evenly">
                    {yearlyplans1.map((plan) => (
                      <Card key={plan.price}>
                        <h4>{plan.price}</h4>
                        <p className="text-sm">{plan.subtitle}</p>
                        <ul>
                          <li>{plan.perks.perk1}</li>
                          <li>{plan.perks.perk2}</li>
                          <li>{plan.perks.perk3}</li>
                          <li>{plan.perks.perk4}</li>
                        </ul>
                        <div className="flex justify-center">
                          <Button onClick={() => handleSelectplan(plan)}>
                            Subscribe
                          </Button>
                        </div>
                      </Card>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-col items-center my-8">
            <h3 className="mb-2">
              Paid Health Check-Up with Wellness Assurance
            </h3>
            <h5 className="mb-0 text-center">
              Free for one Child below 12 years with any following package,
              Wellness Products excluded.
            </h5>

            <div>
              <div className="my-4">
                <div className="md:border-l-2 border-[#307bc4] rounded-tl-lg">
                  <div className="bg-[#307bc4] rounded-lg rounded-bl-none p-3 w-fit my-4">
                    <h4 className="text-white mb-0">3 Months Plans</h4>
                  </div>
                  <div className="flex flex-col lg:flex-row gap-8 flex-wrap justify-evenly">
                    {monthlyplans2.map((plan) => (
                      <Card key={plan.price}>
                        <h4>{plan.price}</h4>
                        <p className="text-sm">{plan.subtitle}</p>
                        <ul>
                          <li>{plan.perks.perk1}</li>
                          <li>{plan.perks.perk2}</li>
                          <li>{plan.perks.perk3}</li>
                          <li>{plan.perks.perk4}</li>
                          <li>{plan.perks.perk5}</li>
                        </ul>
                        <div className="flex justify-center">
                          <Button onClick={() => handleSelectplan(plan)}>
                            Subscribe
                          </Button>
                        </div>
                      </Card>
                    ))}
                  </div>
                </div>
              </div>

              <div>
                <div className="md:border-l-2 border-[#307bc4] rounded-tl-lg">
                  <div className="bg-[#307bc4] rounded-lg rounded-bl-none p-3 w-fit my-4">
                    <h4 className="text-white mb-0">Yearly Plans</h4>
                  </div>
                  <div className="flex flex-col lg:flex-row gap-8 flex-wrap justify-evenly">
                    {yearlyplans2.map((plan) => (
                      <Card key={plan.price}>
                        <h4>{plan.price}</h4>
                        <p className="text-sm">{plan.subtitle}</p>
                        <ul>
                          <li>{plan.perks.perk1}</li>
                          <li>{plan.perks.perk2}</li>
                          <li>{plan.perks.perk3}</li>
                          <li>{plan.perks.perk4}</li>
                          <li>{plan.perks.perk5}</li>
                        </ul>
                        <div className="flex justify-center">
                          <Button onClick={() => handleSelectplan(plan)}>
                            Subscribe
                          </Button>
                        </div>
                      </Card>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default PaidSession;
