import { User } from "lucide-react";
import { Avatar, AvatarFallback, AvatarImage } from "../../Avatar";


export const ProfilePicture = ({ customer,className, src }) => {

  return (
    <Avatar className={className}>
      <AvatarImage
        //do not use ?? operator below instead of || we want to check for falsy values
        src={
          src || customer?.profilePicture
        }
        alt= {customer?.first_name}
      />
      <AvatarFallback className="rounded-full">
        <User size={44}/>
      </AvatarFallback>
    </Avatar>
  );
};
