import React from 'react'
import DashboardLayout from '../../ui/desktop/DashboardLayout'
import HealthReportLayout from './HealthReportLayout'

const HealthReport = () => {
  return (
    <DashboardLayout>
      <div className='w-full'>
        <h1 className='text-center m-6'>My Health Report</h1>
        <div className='mt-12'>
          <HealthReportLayout/>
        </div>
      </div>
    </DashboardLayout>
  )
}

export default HealthReport