import DashboardLayout from "../../../ui/desktop/DashboardLayout";
import DesktopCalendar from "../../../ui/desktop/DesktopCalendar";
import { useState } from "react";
import { useFetchIsSessionBooked } from "../../../../hooks/useFetchIsSessionBooked";
import { useFilteredSessions } from "../../../../hooks/useFilteredSessions";
import moment from "moment/moment";
import Card from "../../../ui/common/Card";
import { Button } from "../../../ui/input/Button";
import { toast } from "sonner";
import { bookFreeSession } from "../../../../api/customer.api";
import { Loader2 } from "lucide-react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectCustomer } from "../../../../store/customerSlice";

const FreeSession = () => {
  const { isSessionBooked } = useFetchIsSessionBooked();
  const [selectedDate, setSelectedDate] = useState();
  const { filteredSessions } = useFilteredSessions(selectedDate);
  const customer = useSelector(selectCustomer);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const onSelect = (date) => {
    setSelectedDate(date);
  };

  const handleBookSession = async (sessionId) => {
    try {
      setLoading(true);
      const response = await bookFreeSession(sessionId, customer.id);

      if (response.message.toLowerCase() === "success") {
        toast.success("Session Booked Successfully");
        navigate("/subscriptions");
      }
    } catch (error) {
      toast.error("Failed to book session, try again later");
      console.error(error, "Couldn't book session");
    } finally {
      setLoading(false);
    }
  };

  return (
    <DashboardLayout>
      <div className="h-[80dvh]">
        <h1 className="m-6">Free Health Session</h1>

        {isSessionBooked ? (
          <div className="m-6 min-h-full">
            <h2 className="text-center">
              You've attended The Free Session, Explore the Paid Sessions for
              more information.
            </h2>
            <div className="flex justify-center items-center mt-8">
              <Button
                className=" text-lg font-semibold font-serif h-[5rem] w-fit"
                onClick={() => {
                  navigate("/paidsession");
                }}
              >
                Book a Paid Health Checkup
              </Button>
            </div>
          </div>
        ) : (
          <div className="w-[70%] mx-auto">
            <h4 className="ml-6 mb-2">Available Dates for Session</h4>
            <p className="ml-6">
              Select an Available Date to See the Session Details and Book a
              Free Session.
            </p>
            <div className="flex gap-12 justify-evenly">
              <div className="w-[45%]">
                <DesktopCalendar
                  onSelect={onSelect}
                  selectedDay={selectedDate}
                />
              </div>
              <div>
                {filteredSessions && filteredSessions.length > 0 && (
                  <div
                    className={`transform transition-all duration-800 ease-in-out m-4 ${
                      filteredSessions.length > 0
                        ? "opacity-100 scale-100"
                        : "opacity-0 scale-95"
                    }`}
                  >
                    <h4 className="mb-8">Available Sessions Details</h4>
                    <div>
                      {filteredSessions.map((session) => (
                        <Card key={session.id}>
                          <div className="flex justify-center">
                            <div>
                              <h5 className="mb-2">
                                Date:{" "}
                                {moment(session.date).format("MMM Do YYYY")}
                              </h5>
                              <h5 className="mb-2">
                                Start Time:{" "}
                                {moment(session.start_time).format("hh:mm a")}
                              </h5>
                              <p className="mb-1">
                                District: {session.district}
                              </p>
                              <p className="mb-1">
                                Pin Code: {session.pin_code}
                              </p>
                              <p className="mb-1">State: {session.state}</p>
                            </div>
                          </div>

                          <div className="flex justify-center items-center min-w-[8rem]">
                            <Button
                              onClick={() => handleBookSession(session.id)}
                            >
                              {loading ? <Loader2 /> : "Book Session"}
                            </Button>
                          </div>
                        </Card>
                      ))}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </DashboardLayout>
  );
};

export default FreeSession;
