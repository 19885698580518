import { CalendarHeart, Cog, HeartPulse, House, MessageSquareDot } from 'lucide-react';
import Diversity1OutlinedIcon from '@mui/icons-material/Diversity1Outlined';
import React from 'react'
import { Link } from 'react-router-dom'

const DesktopSidebarButtons = () => {

  const sidebarButtons = [
    {
      name: "Home",
      icon: <House />,
      link: "/dashboard",
    },
    {
      name: "Health Report",
      icon: <HeartPulse />,
      link: "/healthreport",
    },
    {
      name: "Counselling",
      icon: <Diversity1OutlinedIcon />,
      link: "/counselling",
    },
    {
      name: "Subscriptions",
      icon: <CalendarHeart />,
      link: "/subscriptions",
    },
    {
      name: "Notifications",
      icon: <MessageSquareDot />,
      link: "/notification",
    },
    {
      name: "My Account",
      icon: <Cog />,
      link: "/accountsettings",
    },
  ];

  return (
    <>
      {sidebarButtons.map((button) => (
        <Link to={button.link} key={button.name}>
          <div
            className={`border border-[#307bc4] rounded-full px-5 py-3  ${
              window.location.pathname === button.link
                ? "bg-[#274760] "
                : "hover:bg-[#307bc4]/50"
            }`}
          >
            <h5
              className={`flex gap-2 my-1 ${
                window.location.pathname === button.link ? "text-white" : ""
              }`}
            >
              {button.icon} {button.name}
            </h5>
          </div>
        </Link>
      ))}
    </>
  );
}

export default DesktopSidebarButtons