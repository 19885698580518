import { cn } from '../../../utils/formatting';
import {Button} from '../input/Button';

export const MobileSidebarButton = ({
  children,
  className,
}) => {
  return (
    <Button
      asChild
      size="lg"
      variant="outline"
      className={cn("w-full justify-start", className)}
    >
      {children}
    </Button>
  );
};
