import { DayPicker } from 'react-day-picker';
import { cn } from '../../../utils/formatting';
import { ChevronLeftIcon, ChevronRightIcon } from '@radix-ui/react-icons';

function Calendar ({classNames, className, showOutsideDays = true, ...props}) {
  return (
    <DayPicker
      showOutsideDays={showOutsideDays}
      className={cn(
        "bg-[#f8f9f977] backdrop-blur-xl border border-gray-200 shadow-md rounded-lg p-3",
        className
      )}
      classNames={{
        day: "flex items-center justify-center rounded-lg text-gray-800 hover:bg-[#307bc4]/90 hover:text-white focus:outline-none focus:ring-2 focus:ring-blue-400 transition-all cursor-pointer h-10 w-10 m-1",
        nav: "flex justify-between p-2 bg-gray-100 rounded-t-lg",
        caption_label: "text-lg font-semibold text-gray-900",
        week: "grid grid-cols-7 gap-1",
        weekdays: "grid grid-cols-7 gap-1 rounded-t-lg text-[#274760]",
        ...classNames,
      }}
      components={{
        IconLeft: ({ ...props }) => <ChevronLeftIcon className="h-4 w-4" />,
        IconRight: ({ ...props }) => <ChevronRightIcon className="h-4 w-4" />,
      }}
      {...props}
    />
  );
}
Calendar.displayName = 'Calendar';

export default Calendar;