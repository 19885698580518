import { useEffect, useState } from "react";
import { getFreeSessionSlots } from "../api/customer.api";

export const useFetchFreeSessions = () => {

  const [freeSessions, setFreeSessions] = useState();
  const [isPending, setIspending] = useState(false);  

  useEffect(()=> {
    const fetchSessions = async () => {
      try {
        setIspending(true);
        const response = await getFreeSessionSlots({ state: "assam" });

        if (response.message.toLowerCase() === "success") {
          setFreeSessions(response.data.session_slot);
        }
      } catch (error) {
        console.error(error, "Couldn't get session slots");
      } finally {
        setIspending(false);
      }
    }

    fetchSessions();  
  }, []);

  return {freeSessions, isPending};
};
