import React, { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "../ui/form/form";
import { useForm } from "react-hook-form";
import { Input } from "../ui/input/input";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../ui/input/select";
import { customerSchema } from "../../schemas/user-registration";
import { zodResolver } from "@hookform/resolvers/zod";
import { RegistrationReviewDialog } from "../Dialog/RegistrationReviewDialog";
import { registerCustomer } from "../../api/customer.api";
import { Checkbox, FormControlLabel } from "@mui/material";
import { toast } from "sonner";

export default function AppointmentForm() {
  const form = useForm({ resolver: zodResolver(customerSchema) });

  const [customerDetails, setCustomerDetails] = useState({});
  const [checked, setChecked] = useState(false);
  const [isPending, setIsPending] = useState(false);

  const handleReview = (values) => {
    setCustomerDetails(values);
  };

  const confirmSubmit = async () => {
    setIsPending(true);
    try {
      const response = await registerCustomer(customerDetails);

      if (response.message.toLowerCase() === "success") {
        toast(
          <div>
            <h4 className="text-green-600 text-center text-lg">
              Registration Completed Successfully!
            </h4>
            <p className="text-md">
              Please check your Email for instructions to access your account.
            </p>
          </div>,
          { duration: 6000, closeButton: true }
        );
        setIsPending(false);
      }
    } catch (error) {
      console.error(error);
      if (error.response && error.response.status === 403) {
        toast.error(error.response.data.message);
      } else {
        console.error(error);
        toast.error("Failed to register. Please try again later.");
      }
    } finally {
      setIsPending(false);
    }
  };

  return (
    <>
      <div>
        <div>
          <Form {...form}>
            <form onSubmit={form.handleSubmit(handleReview)}>
              <div className="flex flex-col gap-4">
                <div className="flex flex-col md:flex-row justify-between gap-4 md:gap-0">
                  <FormField
                    control={form.control}
                    name="first_name"
                    defaultValue=""
                    render={({ field }) => (
                      <FormItem classname="grow w-[35%]">
                        <FormLabel>First Name</FormLabel>
                        <FormControl>
                          <Input
                            placeholder="First Name"
                            {...field}
                            className="cs_form_field"
                            error={form.formState.errors.customerName}
                          />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={form.control}
                    name="middle_name"
                    defaultValue=""
                    render={({ field }) => (
                      <FormItem classname="grow w-[33%]">
                        <FormLabel>Middle Name</FormLabel>
                        <FormControl>
                          <Input
                            placeholder="Middle Name"
                            {...field}
                            className="cs_form_field"
                            error={form.formState.errors.customerName}
                          />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={form.control}
                    name="last_name"
                    defaultValue=""
                    render={({ field }) => (
                      <FormItem classname="grow w-[30%]">
                        <FormLabel>Last Name</FormLabel>
                        <FormControl>
                          <Input
                            placeholder="Last Name"
                            {...field}
                            className="cs_form_field"
                            error={form.formState.errors.customerName}
                          />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </div>

                <div className="md:flex-row flex flex-col gap-4 justify-between">
                  <FormField
                    control={form.control}
                    name="date_of_birth"
                    defaultValue=""
                    render={({ field }) => (
                      <FormItem className="grow">
                        <FormLabel>Date of birth</FormLabel>
                        <FormControl>
                          <DatePicker
                            selected={field.value}
                            onChange={(date) => field.onChange(date)}
                            peekNextMonth
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode="select"
                            dateFormat="dd/MM/yyyy"
                            isClearable
                            placeholderText="DD/MM/YYYY"
                          />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={form.control}
                    name="gender"
                    defaultValue={undefined}
                    render={({ field }) => (
                      <FormItem className="grow">
                        <FormLabel>Select Your Gender</FormLabel>
                        <Select
                          onValueChange={field.onChange}
                          value={field.value}
                        >
                          <FormControl>
                            <SelectTrigger>
                              <SelectValue placeholder="Select Gender" />
                            </SelectTrigger>
                          </FormControl>
                          <SelectContent>
                            {["Male", "Female", "Other"].map((gender) => {
                              return (
                                <SelectItem key={gender} value={gender}>
                                  {gender}
                                </SelectItem>
                              );
                            })}
                          </SelectContent>
                        </Select>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </div>
                <div>
                  <div>
                    <FormControlLabel
                      control={
                        <Checkbox
                          onChange={(e) => setChecked(e.target.checked)}
                          checked={checked}
                        />
                      }
                      label="WhatsApp Number is same as Contact Number"
                    />
                  </div>
                  <div className="md:flex-row flex flex-col gap-4 justify-between">
                    <FormField
                      control={form.control}
                      name="phone_no"
                      defaultValue=""
                      render={({ field }) => (
                        <FormItem className="grow">
                          <FormLabel>Contact Number</FormLabel>
                          <FormControl>
                            <Input
                              placeholder="Contact Number"
                              {...field}
                              className="cs_form_field"
                              error={form.formState.errors.contactNumber}
                            />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                    {!checked && (
                      <FormField
                        control={form.control}
                        name="whatsapp_number"
                        defaultValue=""
                        render={({ field }) => (
                          <FormItem className="grow">
                            <FormLabel>WhatsApp Number</FormLabel>
                            <FormControl>
                              <Input
                                placeholder="WhatsApp Number"
                                {...field}
                                className="cs_form_field"
                                error={form.formState.errors.contactNumber}
                              />
                            </FormControl>
                            <FormMessage />
                          </FormItem>
                        )}
                      />
                    )}
                  </div>
                </div>

                <FormField
                  control={form.control}
                  name="email"
                  defaultValue=""
                  render={({ field }) => (
                    <FormItem className="grow">
                      <FormLabel>Email</FormLabel>
                      <FormControl>
                        <Input
                          placeholder="example@gmail.com"
                          {...field}
                          type="email"
                          className="cs_form_field"
                          error={form.formState.errors.email}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="address"
                  defaultValue=""
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Address</FormLabel>
                      <FormControl>
                        <Input
                          placeholder="Enter your Address"
                          {...field}
                          className="input cs_form_field"
                          error={form.formState.errors.address}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <div className="md:flex-row flex flex-col gap-4 justify-between">
                  <FormField
                    control={form.control}
                    name="state"
                    defaultValue=""
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>State</FormLabel>
                        <FormControl>
                          <Input
                            placeholder="Enter your State"
                            {...field}
                            className="input cs_form_field"
                            error={form.formState.errors.address}
                          />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={form.control}
                    name="city"
                    defaultValue=""
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>City</FormLabel>
                        <FormControl>
                          <Input
                            placeholder="Enter your City"
                            {...field}
                            className="input cs_form_field"
                            error={form.formState.errors.address}
                          />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={form.control}
                    name="pin"
                    defaultValue=""
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>Pincode</FormLabel>
                        <FormControl>
                          <Input
                            placeholder="Enter your Pincode"
                            {...field}
                            className="input cs_form_field"
                            error={form.formState.errors.address}
                          />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </div>
              </div>

              <div className="col-lg-12 mt-8">
                {customerDetails && (
                  <RegistrationReviewDialog
                    customerDetails={customerDetails}
                    onClick={confirmSubmit}
                    isPending={isPending}
                  >
                    <button className="cs_btn cs_style_1" type="submit">
                      <span>Review</span>
                      <i>
                        <img src="/images/icons/arrow_white.svg" alt="Icon" />
                        <img src="/images/icons/arrow_white.svg" alt="Icon" />
                      </i>
                    </button>
                  </RegistrationReviewDialog>
                )}
              </div>
            </form>
          </Form>
        </div>
      </div>
    </>
  );
}
