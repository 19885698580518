import React from "react";
import DashboardLayout from "../../ui/desktop/DashboardLayout";
import DesktopHeader from "../../ui/desktop/DesktopHeader";
import { useNavigate } from "react-router-dom";
import { Button } from "../../ui/input/Button";
import DesktopCalendar from "../../ui/desktop/DesktopCalendar";
import { useFetchIsSessionBooked } from "../../../hooks/useFetchIsSessionBooked";
import { useFetchCustomer } from "../../../hooks/useFetchCustomer";
import { Loader2 } from "lucide-react";

const DashboardView = () => {
  const { customerInfo } = useFetchCustomer();
  const { isSessionBooked, isPending } = useFetchIsSessionBooked();
  const navigate = useNavigate();

  return (
    <DashboardLayout>
      <div
        className="min-h-[100vh] w-full bg-cover bg-center"
        style={{
          backgroundImage: "url('/images/home_2/appointment_bg.svg')",
        }}
      >
        {isPending || !customerInfo?.first_name ? (
          <div className="h-full flex justify-center items-center">
            <Loader2 size={60} />
          </div>
        ) : (
          <div className="w-full">
            <div className="hidden lg:block">
              <DesktopHeader name={customerInfo.first_name} />
            </div>
            <div className="lg:flex justify-between gap-4 w-full">
              <div className="flex justify-evenly w-full lg:w-[70%]">
                <div className="flex flex-col gap-8 lg:mt-16">
                  <div className="w-[80%] mx-auto">
                    <h3 className="text-center mt-3">Book a Free Health Session</h3>
                    <div className="text-lg">
                      <p className="mb-8 text-justify">
                        Book Your Free Health Checkup Today! Take charge of your
                        well-being with a comprehensive health checkup — and the
                        best part? It's absolutely free! Our health checkup
                        session is designed to give you a clear picture of your
                        health status, detect potential issues early, and
                        provide personalized guidance for a healthier lifestyle.
                      </p>
                      <h3 className="text-center">
                        What’s Included in Your Free Health Checkup
                      </h3>
                      <ul className="ml-8">
                        <li>
                          Vital Signs Assessment: Blood pressure, heart rate,
                          and oxygen levels.
                        </li>
                        <li>
                          Basic Health Screening: Blood sugar, cholesterol, and
                          BMI check.
                        </li>
                        <li>
                          Personalized Consultation: One-on-one discussion with
                          a healthcare professional.
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="flex flex-col gap-12 justify-center items-center">
                    {!isSessionBooked && (
                      <Button
                        className=" text-lg font-semibold font-serif h-[5rem] w-fit"
                        onClick={() => {
                          navigate("/free-session");
                        }}
                      >
                        Book a Free Health & Wellness Session
                      </Button>
                    )}
                    <Button
                      className=" text-lg font-semibold font-serif h-[5rem] w-fit"
                      onClick={() => {
                        navigate("/paid-session");
                      }}
                      disabled={!isSessionBooked}
                    >
                      Book a Paid Health Checkup
                    </Button>
                  </div>
                </div>
              </div>
              <div className="lg:p-4 mt-20">
                <DesktopCalendar />
              </div>
            </div>
          </div>
        )}
      </div>
    </DashboardLayout>
  );
};

export default DashboardView;
