import React from "react";
import DashboardLayout from "../../ui/desktop/DashboardLayout";
import { Loader } from "lucide-react";
import moment from "moment";
import { useFetchCustomer } from "../../../hooks/useFetchCustomer";

const AccountSettings = () => {
  const { customerInfo, isPending } = useFetchCustomer();

  return (
    <DashboardLayout>
      <div
        className="min-h-[100vh] w-full bg-cover bg-center flex items-center justify-center"
        style={{
          backgroundImage: "url('/images/home_2/appointment_bg.svg')",
        }}
      >
        <div className="w-fit h-fit py-8 md:px-8 my-auto backdrop-blur-xl shadow-lg shadow-[#307bc4] rounded-lg bg-[#fbfbfb84]">
          <h1 className="text-center">My Account</h1>
          {isPending || !customerInfo ? (
            <div className="w-full flex items-center justify-center">
              <Loader />
            </div>
          ) : (
            <div className="mx-auto w-fit p-4 border-1 border-[#307bc4] rounded-lg">
              <div className="flex gap-2 my-8">
                <div>
                  <h5 className="capitalize">Name:</h5>
                  <h5>Email: </h5>
                  <h5>Contact Number: </h5>
                  <h5>WhatsApp Number: </h5>
                  <h5>Date of Birth: </h5>
                  <h5>Gender: </h5>
                  <h5>City: </h5>
                  <h5>State: </h5>
                  <h5>Address: </h5>
                  <h5>Pin Code: </h5>
                </div>
                <div>
                  <h5>
                    {customerInfo.first_name} {customerInfo.middle_name}{" "}
                    {customerInfo.last_name}
                  </h5>
                  <h5>{customerInfo.email}</h5>
                  <h5>{customerInfo.phone_no ? customerInfo.phone_no : ""}</h5>
                  <h5>{customerInfo.whatsapp_number ? customerInfo.whatsapp_number : customerInfo.phone_no}</h5>
                  <h5>
                    {moment(customerInfo.date_of_birth).format("MMM Do YYYY")}
                  </h5>
                  <h5>{customerInfo.gender}</h5>
                  <h5>{customerInfo.city}</h5>
                  <h5>{customerInfo.state}</h5>
                  <h5>{customerInfo.address}</h5>
                  <h5>{customerInfo.pin}</h5>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </DashboardLayout>
  );
};

export default AccountSettings;
