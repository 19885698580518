import React from 'react'
import DashboardLayout from '../../ui/desktop/DashboardLayout'

const Notification = () => {
  return (
    <DashboardLayout>
      <h1>Notification</h1>
    </DashboardLayout>
  )
}

export default Notification