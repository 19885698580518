import React from "react";
import DashboardLayout from "../../ui/desktop/DashboardLayout";
import { Button } from "../../ui/input/Button";
import { useNavigate } from "react-router-dom";
import Card from "../../ui/common/Card";
import { Minus } from "lucide-react";
import {
  monthlyplans1,
  monthlyplans2,
  yearlyplans1,
  yearlyplans2,
} from "../../../utils/subscribtionPlans";

const Subscriptions = () => {
  const navigate = useNavigate();

  return (
    <DashboardLayout>
      <div className="w-full flex flex-col items-center justify-center text-center p-3">
        <h4>You have not subscribed to any plans yet.</h4>
        <h4>Book a Paid Health Checkup to see your Subscribed Plans Details</h4>
        <Button
          className=" text-lg font-semibold font-serif h-[5rem] w-fit"
          onClick={() => {
            navigate("/paid-session");
          }}
        >
          Book a Paid Health Checkup
        </Button>
      </div>
      <div className="my-16">
        <div className="py-8 px-4">
          <h2>My Current Plan</h2>
          <div className="shadow-inner shadow-[#bcd8f3] lg:p-3 rounded-lg py-3">
            <Card>
              <h4 className="text-center">3 Months Plans</h4>
              <ul>
                {monthlyplans1.map((plan, index) => (
                  <li
                    key={index}
                    className="list-none flex items-center gap-2 mb-2"
                  >
                    <Minus />
                    <div>
                      <p className="mb-0 text-[#274760]">{plan.price}</p>
                      <p className="text-sm">{plan.subtitle}</p>
                    </div>
                  </li>
                ))}
              </ul>
              <div className="w-full flex justify-center">
                <Button
                  className="w-[80%]"
                  variant="outline"
                  onClick={() => navigate("/paid-session")}
                >
                  Renew
                </Button>
              </div>
            </Card>
          </div>
        </div>
        <div className="mt-8 px-4">
          <h2>Other Available Plans</h2>
          <div className="mb-12 shadow-inner shadow-[#bcd8f3] lg:p-3 rounded-lg py-3">
            <h4 className="text-center">Paid Health Check-Up</h4>
            <div className="flex items-end justify-evenly flex-wrap gap-3">
              <Card>
                <h4 className="text-center">3 Months Plans</h4>
                <ul>
                  {monthlyplans1.map((plan, index) => (
                    <li
                      key={index}
                      className="list-none flex items-center gap-2 mb-2"
                    >
                      <Minus />
                      <div>
                        <p className="mb-0 text-[#274760]">{plan.price}</p>
                        <p className="text-sm">{plan.subtitle}</p>
                      </div>
                    </li>
                  ))}
                </ul>
                <div className="w-full flex justify-center">
                  <Button
                    className="w-[80%]"
                    variant="outline"
                    onClick={() => navigate("/paid-session")}
                  >
                    Update
                  </Button>
                </div>
              </Card>
              <div className="border-1 border-[#307bc4] rounded-[1rem] bg-[#307bc4]">
                <h5 className="text-center my-3 text-white">Most Popular</h5>
                <Card>
                  <h4 className="text-center">Yearly Plans</h4>
                  <ul>
                    {yearlyplans1.map((plan, index) => (
                      <li
                        key={index}
                        className="list-none flex items-center gap-2 mb-2"
                      >
                        <Minus />
                        <div>
                          <p className="mb-0 text-[#274760]">{plan.price}</p>
                          <p className="text-sm">{plan.subtitle}</p>
                        </div>
                      </li>
                    ))}
                  </ul>
                  <div className="w-full flex justify-center">
                    <Button
                      className="w-[80%]"
                      onClick={() => navigate("/paid-session")}
                    >
                      Update
                    </Button>
                  </div>
                </Card>
              </div>
            </div>
          </div>

          <div className="my-8 shadow-inner shadow-[#bcd8f3] lg:p-3 rounded-lg py-3">
            <h4 className="text-center">
              Paid Health Check-Up with Wellness Assurance
            </h4>
            <div className="flex items-end justify-evenly flex-wrap gap-3">
              <Card>
                <h4 className="text-center">3 Months Plans</h4>
                <ul>
                  {monthlyplans2.map((plan, index) => (
                    <li
                      key={index}
                      className="list-none flex items-center gap-2 mb-2"
                    >
                      <Minus />
                      <div>
                        <p className="mb-0 text-[#274760]">{plan.price}</p>
                        <p className="text-sm">{plan.subtitle}</p>
                      </div>
                    </li>
                  ))}
                </ul>
                <div className="w-full flex justify-center">
                  <Button
                    className="w-[80%]"
                    variant="outline"
                    onClick={() => navigate("/paid-session")}
                  >
                    Update
                  </Button>
                </div>
              </Card>
              <div className="border-1 border-[#307bc4] rounded-[1rem] bg-[#307bc4]">
                <h5 className="text-center my-3 text-white">Most Popular</h5>
                <Card>
                  <h4 className="text-center">Yearly Plans</h4>
                  <ul>
                    {yearlyplans2.map((plan, index) => (
                      <li
                        key={index}
                        className="list-none flex items-center gap-2 mb-2"
                      >
                        <Minus />
                        <div>
                          <p className="mb-0 text-[#274760]">{plan.price}</p>
                          <p className="text-sm">{plan.subtitle}</p>
                        </div>
                      </li>
                    ))}
                  </ul>
                  <div className="w-full flex justify-center">
                    <Button
                      className="w-[80%]"
                      onClick={() => navigate("/paid-session")}
                    >
                      Update
                    </Button>
                  </div>
                </Card>
              </div>
            </div>
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default Subscriptions;
