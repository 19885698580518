import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isAuthenticated: false,
  token: null,
  refreshToken: null,
  expiry: null,
}

const authSlice = createSlice({
  initialState, 
  name: "authUser",
  reducers: {
    userLogin : (state, action) => {
      const { AccessToken, RefreshToken, ExpiresIn } = action.payload;
      state.isAuthenticated = true;
      state.token = AccessToken;
      state.refreshToken = RefreshToken;
      state.expiry = Date.now() + ExpiresIn * 1000;
    },
    userLogout: (state) => {
      state.isAuthenticated = false;
      state.token = null;
      state.refreshToken = null;
      state.expiry = null;
    }
  }
})

export const {userLogin, userLogout} = authSlice.actions; 
export default authSlice.reducer;