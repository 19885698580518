import { Toaster } from "sonner";
import { RouteManager } from "./RouteManager";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import store from "./store/store";
import AuthWatcher from "./hooks/AuthWatcher";

function App() {
  return (
    <Provider store={store}>
      <Toaster richColors position="top-right" />
      <AuthWatcher/>
      <BrowserRouter>
        <RouteManager />
      </BrowserRouter>
    </Provider>
  );
}

export default App;
