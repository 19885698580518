import { useLocation } from "react-router-dom"
import DashboardLayout from "../../../ui/desktop/DashboardLayout"
import Card from "../../../ui/common/Card";
import { Button } from "../../../ui/input/Button";
import { useSelector } from "react-redux";
import { selectCustomer } from "../../../../store/customerSlice";
import { getPaymentDetails, subscribePlan } from "../../../../api/customer.api";
import { toast } from "sonner";
import { useState } from "react";
import { Loader } from "lucide-react";


const Payment = () => {

  const location = useLocation();
  const {plan} = location.state || {};
  const customer = useSelector(selectCustomer);
  const [loading, setLoading] = useState(false);

  const handlePayment = async() => {
    setLoading(true);
    try {
      const response = await subscribePlan(customer.id, plan.service, plan.duration, plan.member);

      if(response.message.toLowerCase() === "success") {

        const subscriptionId = response.data.subscription[0].id;
        
        await getPaymentData(subscriptionId);
      }
    } catch (error) {
      toast.error("Failed to Subscribe!");
      console.error(error, "Failed to Subscribe Plan!");
    } finally {
      setLoading(false);
    }
  }

  const getPaymentData = async (subscriptionId) => {
    setLoading(true);
    try {
      const response = await getPaymentDetails(subscriptionId, customer.id);

      if(response.message.toLowerCase() === "success") {

        const encRequest = response.data.encRequest;
        const access_code = response.data.access_code;
        const url = response.data.url;

        submitPaymentForm(encRequest, access_code, url);
      }

    } catch (error) {
      console.error(error, "Couldn't get payment data");
    } finally {
      setLoading(false);
    }
  }

  const submitPaymentForm = (encRequest, access_code, url ) => {

    const form = document.getElementById('nonseamless');

    form.action = url;
    document.getElementById('encRequest').value = encRequest;
    document.getElementById('access_code').value = access_code;

    form.submit();
  }

  const PaymentForm = () => {
    return (
      <>
        <form id="nonseamless" method="post" name="redirect" action=""> 
          <input type="hidden" id="encRequest" name="encRequest" value="' + encRequest + '"/>
          <input type="hidden" name="access_code" id="access_code" value="' + accessCode + '"/>
        </form>
      </>
    )
  }

  return (
    <DashboardLayout>
      <div
        className="min-h-[90dvh] lg:min-h-[100vh] w-full bg-cover bg-center"
        style={{
          backgroundImage: "url('/images/home_2/appointment_bg.svg')",
        }}
      >
        <div className="p-6">
          <h2 >Selected Plan</h2>
          <div className="lg:px-4">
            <h3>
              {plan.duration === 3 ? (
                <span>3 Months </span>
              ) : (
                <span>12 Months </span>
              )}
              {plan.service === "type1" ? (
                <span>Paid Health Check-Up</span>
              ) : (
                <span>Paid Health Check-Up with Wellness Assurance</span>
              )}
            </h3>
            <Card className="min-w-[342px]">
              <h4 className="mb-0 text-[#274760]">{plan.price}</h4>
              <p className="text-sm">{plan.subtitle}</p>
              <ul>
                <li>{plan.perks.perk1}</li>
                <li>{plan.perks.perk2}</li>
                <li>{plan.perks.perk3}</li>
                <li>{plan.perks.perk4}</li>
                {plan.service === "type2" && <li>{plan.perks.perk5}</li>}
              </ul>
              <div className="w-full flex justify-center">
                <Button onClick={() => handlePayment()}>
                  {loading ? <Loader /> : "Make Payment"}
                </Button>
              </div>
            </Card>
          </div>
        </div>
        <PaymentForm />
      </div>
    </DashboardLayout>
  );
}

export default Payment