import React, { useState } from 'react'
import { Form, FormControl, FormField, FormItem, FormMessage } from '../ui/form/form';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { LoginSchema } from '../../schemas/login';
import { FancyInput } from '../ui/input/input';
import { PasswordToggleWrapper } from '../ui/password-toggle-wrapper';
import { TermsAgreement } from '../ui/TermsAgreement';
import { Button } from '../ui/input/Button';
import { Loader2 } from 'lucide-react';
import { signup } from '../../api/auth.api';
import { toast } from 'sonner';

export default function SignupForm ({onSignup}){

  const form = useForm({resolver: zodResolver(LoginSchema)});

  const [isPending, setIsPending] = useState(false);
  const [hasAgreedTerms, setHasAgreedTerms] = useState(false);

  const onSubmit = async (values) => {
    try {
      setIsPending(true);
      const session = await signup(values);
      if(session.message.toLowerCase() === "success") {
        onSignup();
      }
    } catch (error) {
      if (error && error.response?.status === 404) {
        form.setError("password", {
          message: error.response.data.message
        });
      } else if (error && error.response?.status === 403) {
        toast.error(error.response.data.message);
        form.setError("email", {
          message: error.response.data.message
        });
      } else if (error && error.response?.status === 500) {
        toast.info(`${error.response.data.data.message}, Please Login!`);
        form.setError("email", {
          message: error.response.data.data.message
        });
      } else {
        toast.error("Something went wrong, Please try again!")
        console.error(error);
      }
    } finally {
      setIsPending(false);
    }
  };

  return (
    <div>
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <div className="flex flex-col gap-4">
            <div>
              <FormField
                control={form.control}
                name="email"
                render={({ field }) => (
                  <FormItem>
                    <FormControl>
                      <FancyInput
                        label="Email"
                        error={form.formState.errors.email}
                        {...field}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
            <div>
              <FormField
                control={form.control}
                name="password"
                render={({ field }) => (
                  <FormItem>
                    <FormControl>
                      <PasswordToggleWrapper>
                        <FancyInput
                          label="Password"
                          error={form.formState.errors.password}
                          {...field}
                        />
                      </PasswordToggleWrapper>
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              {
                form.formState.errors.password && (
                  <div className="text-sm text-gray-400">
                    <p className="m-0">
                      Password must contain at least 2 uppercase letters, 2 lowercase letters, 2 numbers, 2 special characters(@$!%*?&)
                    </p>
                  </div>
                )
              }
            </div>
            <div className="flex flex-col gap-3 my-6 items-center">
              <TermsAgreement
                onCheckedChange={setHasAgreedTerms}
                checked={hasAgreedTerms}
              />
              <Button
                className="w-[80%] flex justify-center"
                isPending={isPending}
                disabled={isPending || !hasAgreedTerms}
              >
                {isPending ? (
                  <Loader2 className="ml-3 animate-spin" />
                ) : (
                  "Sign Up"
                )}
              </Button>
            </div>
          </div>
        </form>
      </Form>
    </div>
  );
}
