import React from "react";
import { Button } from "../../../ui/input/Button";
import PaymentSuccessLoader from "../../../ui/common/payment-success-loader";

const PaymentSuccess = () => {
  return (
    <div className="w-full h-[90dvh]">
      <div className="p-2 flex justify-center mt-[10rem]">
        <div>
          <div className="flex justify-center items-cente px-6 mb-8">
            <img src="/images/nhwm.png" alt="Logo" className="size-12 mr-3" />
            <span className="font-bold text-2xl flex items-center">NHWM</span>
          </div>
          <div className="border-1 border-green-400 rounded-xl p-4">
            <PaymentSuccessLoader />
          </div>
          <div className="flex justify-center mt-12">
            <Button onClick={() => {window.location.href = '/dashboard'}}>Go Home</Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentSuccess;
