import { useEffect, useState } from "react";
import { useFetchFreeSessions } from "./useFetchFreeSessions";
import { formatDate } from "../utils/formatting";

export const useFilteredSessions = (date) => {
  const [filteredSessions, setFilteredSessions] = useState();
  const [isPending, setIsPending] = useState(false);
  const { freeSessions } = useFetchFreeSessions();

  useEffect(() => {
    if (Array.isArray(freeSessions) && date) {
      try {
        setIsPending(true);
        const filteredSessions = freeSessions.filter((session) => {
          const convertedSessionDate = formatDate(new Date(session.date));
          const convertSelectedDate = formatDate(new Date(date));

          return convertedSessionDate === convertSelectedDate;
        });
        setFilteredSessions(filteredSessions);
      } catch (error) {
        console.error(error, "Couldn't get Sessions");
      } finally {
        setIsPending(false);
      };
      
    }
  }, [date]);

  return {filteredSessions, isPending};
};
