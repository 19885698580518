import { useEffect, useState } from "react"
import { getCustomerInfo } from "../api/customer.api";
import { useDispatch } from "react-redux";
import { setCustomer } from "../store/customerSlice";

export const useFetchCustomer = () => {
  
  const dispatch = useDispatch();
  const [customerInfo, setCustomerInfo] = useState();
  const [isPending, setIspending] = useState(false);
  
  const fetchCustomerInfo = async () => {
    try {
      setIspending(true);
      const response = await getCustomerInfo();
      if(response.message.toLowerCase() === "success") {
        setCustomerInfo(response.data.customer);
        dispatch(setCustomer(response.data.customer));
      }
    } catch (error) {
      console.error(error, "Failed to fetch the customer details in usefetchcustomer hook.");
    } finally {
      setIspending(false);
    }
  }

  useEffect(()=> {
    fetchCustomerInfo();
  },[]);

  return {customerInfo, isPending};

}