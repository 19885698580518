import { z } from "zod";

export const customerSchema = z.object({
  first_name: z.string().min(5, "Please Enter your Name").regex(/^[a-zA-Z\s]+$/, "Name must only contain alphabets and spaces."),
  middle_name: z.string().optional(),
  last_name: z.string().min(2, "Please Enter your Name").regex(/^[a-zA-Z\s]+$/, "Name must only contain alphabets and spaces."),
  date_of_birth: z.date(), // string
  gender: z.string().min(3,"Please select your Gender"),
  phone_no: z.string().min(10, "Please Enter your Contact Number.").regex(/^\+?\d{10,15}$/, "Enter a valid Contact Number."),
  whatsapp_number: z.string().optional(),
  email: z.string().email("Enter a valid Email Address"),
  address: z.string().min(5, "Enter your address"), 
  state: z.string().min(2, "Please Enter your State"), 
  pin: z.string().min(2, "Please Enter your Pincode").regex(/^\d+$/, "Enter a valid Pincode" ),
  city: z.string().min(2, "Please Enter your City"),
});
